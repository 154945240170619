import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  recentRooms: [],
  recentUsers: [],
  AVPageRefresh: null,
};
export const persistSclice = createSlice({
  name: 'persist',

  initialState,
  reducers: {
    setRecentRooms: (state, action) => {
      state.recentRooms = action.payload;
    },
    setRecentUsers: (state, action) => {
      state.recentUsers = action.payload;
    },
    setAVPageRefresh: (state, action) => {
      state.AVPageRefresh = action.payload;
    },
  },
});

export const {setRecentRooms, setRecentUsers, setAVPageRefresh} =
  persistSclice.actions;

export default persistSclice.reducer;
