import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Conference } from "./components/conference";

export const conference = {
  _room: null,
  get membersCount() {
    return this._room?.getParticipants().length + 1;
  },
  getConnectionState() {
    return this._room && this._room?.getConnectionState();
  },
  getStats() {
    return this._room && this._room?.connectionQuality.getStats();
  },
};
window.APP = {
  conference,
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:room" element={<Conference />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
