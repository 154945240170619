import React from 'react';
import { checkLinkSubstitution } from '../../utils/functions';

const ExtensionWebpage = ({ extensionDetails }) => {
  return (
    <iframe
      title={extensionDetails?.name}
      src={checkLinkSubstitution(extensionDetails?.url)}
      width="100%"
      height="100%"
    />
  );
};

export default ExtensionWebpage;
