import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkLinkSubstitution } from '../../utils/functions';

function FeaturedChatMessage() {
    const { featuredMsg, participantsList, screenDimension } = useSelector(
        state => state.conf,
    );

    const { name, message, } = featuredMsg;
    let { screenHeight, screenWidth } = screenDimension;

    let profile_image = featuredMsg?.profile_image;
    let userName = name;

    const featuredMsgUser = useMemo(() => {
        return participantsList[featuredMsg.token]
            ? participantsList[featuredMsg.token]
            : {};
    }, [participantsList, featuredMsg]);

    if (Object.keys(featuredMsgUser).length > 0) {
        userName = featuredMsgUser.name;
        profile_image = featuredMsgUser.profile_image;
    }

    if (featuredMsg?.hide_chat_for_guests) {
        return <></>;
    }

    let featureChatStyle = {
        bottom: `${(screenHeight * 6.5 / 100)}px`,
        left: `${screenWidth * 3 / 100}px`,
    }

    let featureChatTextStyle = {
        maxHeight: `${(screenHeight * 12 / 100)}px`,
        maxWidth: `${(screenWidth * 65 / 100)}px`,
        minWidth: `${screenWidth * 20 / 100}px`,
    };

    return (
        <>
            <div className={`d-flex   position-absolute `} style={featureChatStyle} >
                <div className="featured_cir bg-dark d-flex align-items-center justify-content-center bg-white text-green">
                    {profile_image ? (
                        <img src={profile_image} alt="" />
                    ) : (
                        <span>{userName && userName[0]}</span>
                    )}
                </div>

                <div className="featured_modal d-flex flex-column position-relative">
                    <div
                        className={'featured_head pl-5 py-2 text-white w-55'}>
                        {userName}
                    </div>
                    <div className="featured_text bg-white pl-5 py-3 pr-3" style={featureChatTextStyle}>
                        {checkLinkSubstitution(message)}
                    </div>
                </div>
            </div >
        </>
    );
}

export default React.memo(FeaturedChatMessage);
