/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import AttendeeName from './attendeeName';
import { _getAvatarStyle, getAvatarColor } from '../../utils/functions';
import { useSelector } from 'react-redux';

function Avatar({ participant, key, showWarning, streamStatus }) {
    const roomDetail = useSelector(state => state.conf.roomDetail);
    const [showIcon, setShowIcon] = useState(false);
    const iconAccess = useMemo(() => {
        return (roomDetail?.triangleOnAvatarDuringVideoInterrupted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomDetail?.triangleOnAvatarDuringVideoInterrupted]);

    const showWarmingIcon = useMemo(() => {
        return (
            iconAccess && showWarning &&
            (streamStatus === 'restoring' || streamStatus === 'interrupted')
        );
    }, [showWarning, streamStatus, iconAccess]);

    useEffect(() => {
        let debounceTimer = 0;
        if (showWarmingIcon) {
            debounceTimer = setTimeout(() => {
                if (showWarmingIcon) {
                    setShowIcon(true);
                }
            }, 3000);
        }

        return () => {
            clearTimeout(debounceTimer);
            setShowIcon(false);
        };
    }, [showWarmingIcon]);

    console.log('showIcon', showIcon);

    return (

        <div
            className="avatarParticipantName avatar-foreign"
            style={_getAvatarStyle(getAvatarColor(participant?.name))}
            key={key || ''}
        >
            {
                showIcon && (
                    <div className="helping-tooltip-warning">
                        <a
                            className="text-yellow ml-0 d-flex"
                        >
                            <i className="fas fa-exclamation-triangle" />
                        </a>
                    </div>
                )}
            {
                participant?.profile_image ? <>
                    <img
                        src={participant.profile_image}
                    />
                    <AttendeeName name={participant?.name} />
                </> : participant?.name
            }

        </div>
    );

}

export default Avatar;
