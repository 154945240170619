/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react';
import { checkLinkSubstitution } from '../../utils/functions';

const ExtensionCTA = ({ extensionDetails }) => {
  const [popupBtn, setPopupBtn] = useState(false);
  const [popupHed, setPopupHed] = useState(false);
  const [backgroundWhite, setBackgroundWhite] = useState(true);
  const [imageClass, setImageClass] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [bgImageNColor, setBgImageNColor] = useState();

  const hexToDecimal = hex => parseInt(hex, 16);

  const newShade = (hexColor, magnitude) => {
    hexColor = hexColor.replace('#', '');
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  useEffect(() => {
    const extensionURL = 'https://cta-widget.meetn.com';
    console.log('extensionDetails', extensionDetails)
    if (extensionDetails) {
      // if background button on
      if (extensionDetails?.backGroundBtn) {
        if (extensionDetails?.hexaColorOrBdImage.includes('/static/media')) {
          let image = `${extensionURL}${extensionDetails?.hexaColorOrBdImage}`;

          let lastSlashIndex = image.lastIndexOf('/');
          let img = image.substring(lastSlashIndex + 1).split('.');
          let imgName = `${img[0]}.${img[2]}`;
          console.log('imgName', imgName);
          if (extensionDetails.selectColor.includes('Dark-Colored')) {
            image = `${window.location.origin}/assets/widgets/cta/images/darkImages/${imgName}`;
          } else if (extensionDetails.selectColor.includes('Light-Colored')) {
            image = `${window.location.origin}/assets/widgets/cta/images/lightImages/${imgName}`;
          }

          let image2 = `url(${image})`;
          setBgImageNColor(image2);
          setBackgroundWhite(false);
        } else if (extensionDetails.selectColor.includes('Dark-Colored')) {
          console.log('set Dark-Colored');
          let image = `${window.location.origin}/assets/widgets/cta/images/darkImages/${extensionDetails?.hexaColorOrBdImage}`;
          let image2 = `url(${image})`;
          setBgImageNColor(image2);
          setBackgroundWhite(false);
        } else if (extensionDetails.selectColor.includes('Light-Colored')) {
          console.log('set Light-Colored');
          let image = `${window.location.origin}/assets/widgets/cta/images/lightImages/${extensionDetails?.hexaColorOrBdImage}`;
          let image2 = `url(${image})`;
          setBgImageNColor(image2);
          setBackgroundWhite(false);
        } else if (extensionDetails?.addGradientBtn) {
          let getBrightColor = '';
          let getDarkColor = '';
          let color = '';
          if (
            hexToDecimal(
              extensionDetails?.hexaColorOrBdImage.replace('#', ''),
            ) > hexToDecimal('808080')
          ) {
            getDarkColor = newShade(extensionDetails?.hexaColorOrBdImage, -85);
          } else {
            getBrightColor = newShade(extensionDetails?.hexaColorOrBdImage, 85);
          }
          color = getDarkColor
            ? `linear-gradient(to bottom, ${extensionDetails?.hexaColorOrBdImage}, ${getDarkColor})`
            : `linear-gradient(to bottom, ${getBrightColor}, ${extensionDetails?.hexaColorOrBdImage})`;
          setBgImageNColor(color);

          if (color.includes('#000000')) {
            setBackgroundWhite(true);
          } else if (color.includes('#ffffff')) {
            setBackgroundWhite(false);
          } else {
            setBackgroundWhite(true);
          }
        } else if (!extensionDetails?.addGradientBtn) {
          setBgImageNColor(extensionDetails?.hexaColorOrBdImage);
          document.getElementById('rootDiv').style.backgroundColor =
            extensionDetails?.hexaColorOrBdImage;
          document.getElementById('rootDiv').style.backgroundImage = null;

          let colorCo̥de = extensionDetails?.hexaColorOrBdImage.substring(0, 2);
          if (colorCo̥de === '#f' || colorCo̥de === '#e') {
            setBackgroundWhite(false);
          } else if (
            colorCo̥de === '#0' ||
            colorCo̥de === '#1' ||
            colorCo̥de === '#2'
          ) {
            setBackgroundWhite(true);
          }
        }
      }
      //if background btn off
      else {
        setBackgroundWhite(false);
        setBgImageNColor('#ffffff');

        document.getElementById('rootDiv').style.backgroundImage = null;
        document.getElementById('rootDiv').style.backgroundColor = '#ffffff';
      }
      //add class for PopUp style
      if (extensionDetails?.bgLayout === 'PopUp') {
        if (
          extensionDetails?.headlineBtn === false &&
          extensionDetails?.subheadlineBtn === false &&
          extensionDetails?.featuredImageBtn === false
        ) {
          setPopupBtn(true);
        }
        if (extensionDetails?.featuredImageBtn === false) {
          setPopupHed(true);
        } else {
          setPopupBtn(false);
          setPopupHed(false);
        }
      } else {
        setPopupBtn(false);
        setPopupHed(false);
      }

      // add dynamic classes for all display styles(for bgLayout) to view how text font-size will be display
      let headlineCount = 0;
      let subheadlineCount = 0;
      if (extensionDetails?.headlineBtn) {
        headlineCount = extensionDetails?.headline.length;
      }
      if (extensionDetails?.subheadlineBtn) {
        subheadlineCount = extensionDetails?.subheadline.length;
      }
      let totalLength = headlineCount + subheadlineCount;
      if (
        extensionDetails?.bgLayout === 'TopBar' ||
        extensionDetails?.bgLayout === 'BottomBar'
      ) {
        if (totalLength > 130 && totalLength < 200) {
          setFontSize('font-size-1');
        } else if (totalLength >= 200 && totalLength < 270) {
          setFontSize('font-size-2');
        } else if (totalLength >= 270) {
          setFontSize('font-size-3');
        } else {
          setFontSize('');
        }
      } else if (extensionDetails?.bgLayout === 'PopUp') {
        if (totalLength > 80 && totalLength < 100) {
          setFontSize('font-size-1');
        } else if (totalLength >= 100 && totalLength < 150) {
          setFontSize('font-size-2');
        } else if (totalLength >= 150 && totalLength < 220) {
          setFontSize('font-size-3');
        } else if (totalLength >= 220) {
          setFontSize('font-size-4');
        } else {
          setFontSize('');
        }
      } else if (
        extensionDetails?.bgLayout === 'RightSidebar' ||
        extensionDetails?.bgLayout === 'LeftSidebar'
      ) {
        if (totalLength > 130 && totalLength < 190) {
          setFontSize('font-size-1');
        } else if (totalLength >= 190 && totalLength < 270) {
          setFontSize('font-size-2');
        } else if (totalLength >= 270) {
          setFontSize('font-size-3');
        } else {
          setFontSize('');
        }
      }

      // add dynamic classes to show image on PopUps
      if (extensionDetails?.headlineBtn || extensionDetails?.subheadlineBtn) {
        const hedlinecount = extensionDetails?.headline.length;
        const subhedlinecount = extensionDetails?.subheadline.length;
        if (
          (hedlinecount < 70 && subhedlinecount < 50) ||
          (hedlinecount < 35 && subhedlinecount < 98) ||
          hedlinecount < 35 ||
          subhedlinecount < 50 ||
          hedlinecount < 70 ||
          subhedlinecount < 98 ||
          (hedlinecount < 35 && subhedlinecount < 50)
        ) {
          setImageClass('text-row-3');
        } else if (
          (hedlinecount < 105 && subhedlinecount < 50) ||
          (hedlinecount < 35 && subhedlinecount < 147) ||
          (hedlinecount < 70 && subhedlinecount < 98)
        ) {
          setImageClass('text-row-4');
        } else if (
          (hedlinecount < 70 && subhedlinecount < 150) ||
          (hedlinecount < 105 && subhedlinecount < 100) ||
          (hedlinecount < 140 && subhedlinecount < 50)
        ) {
          setImageClass('text-row-5');
        } else if (
          (hedlinecount < 70 && subhedlinecount < 150) ||
          (hedlinecount < 105 && subhedlinecount < 100) ||
          (hedlinecount > 140 && subhedlinecount < 50)
        ) {
          setImageClass('text-row-6');
        } else if (
          (hedlinecount < 140 && subhedlinecount < 150) ||
          (hedlinecount > 140 && subhedlinecount < 100)
        ) {
          setImageClass('text-row-7');
        } else {
          setImageClass('text-row-7');
        }
      }
    }
    if (!extensionDetails.featuredImageBtn) {
      setLoaded(true);
      // if background button on
      if (extensionDetails?.backGroundBtn) {
        if (extensionDetails?.hexaColorOrBdImage.includes('/static/media')) {
          // let image =
          //   window.location.origin + extensionDetails?.hexaColorOrBdImage;
          let image = `${extensionURL}${extensionDetails?.hexaColorOrBdImage}`;
          let image2 = `url(${image})`;
          document.getElementById('rootDiv').style.backgroundImage = image2;
          setBackgroundWhite(false);
        } else if (extensionDetails?.addGradientBtn) {
          let getBrightColor = '';
          let getDarkColor = '';
          let color = '';
          if (
            hexToDecimal(
              extensionDetails?.hexaColorOrBdImage.replace('#', ''),
            ) > hexToDecimal('808080')
          ) {
            getDarkColor = newShade(extensionDetails?.hexaColorOrBdImage, -85);
          } else {
            getBrightColor = newShade(extensionDetails?.hexaColorOrBdImage, 85);
          }
          color = getDarkColor
            ? `linear-gradient(to bottom, ${extensionDetails?.hexaColorOrBdImage}, ${getDarkColor})`
            : `linear-gradient(to bottom, ${getBrightColor}, ${extensionDetails?.hexaColorOrBdImage})`;
          setBgImageNColor(color);
          document.getElementById('rootDiv').style.backgroundImage = color;

          if (color.includes('#000000')) {
            setBackgroundWhite(true);
          } else if (color.includes('#ffffff')) {
            setBackgroundWhite(false);
          } else {
            setBackgroundWhite(true);
          }
        } else if (!extensionDetails?.addGradientBtn) {
          document.getElementById('rootDiv').style.backgroundColor =
            extensionDetails?.hexaColorOrBdImage;

          let colorCo̥de = extensionDetails?.hexaColorOrBdImage.substring(0, 2);
          if (colorCo̥de === '#f' || colorCo̥de === '#e') {
            setBackgroundWhite(false);
          } else if (
            colorCo̥de === '#0' ||
            colorCo̥de === '#1' ||
            colorCo̥de === '#2'
          ) {
            setBackgroundWhite(true);
          }
        }
      }
      //if background btn off
      else {
        setBackgroundWhite(false);
        document.getElementById('rootDiv').style.backgroundImage = '';
      }
    }
  }, [extensionDetails]);

  useEffect(() => {
    if (bgImageNColor) {
      if (bgImageNColor?.includes('#' && !'to bottom')) {
        document.getElementById('rootDiv').style.backgroundColor =
          bgImageNColor;
      } else if (bgImageNColor?.includes('#' && 'to bottom')) {
        document.getElementById('rootDiv').style.backgroundImage =
          bgImageNColor;
      } else {
        document.getElementById('rootDiv').style.backgroundImage =
          bgImageNColor;
      }
    }
  }, [bgImageNColor]);

  const loadedFunction = () => {
    setLoaded(true);
    if (bgImageNColor?.includes('#' && !'to bottom')) {
      document.getElementById('rootDiv').style.backgroundColor = bgImageNColor;
    } else if (bgImageNColor?.includes('#' && 'to bottom')) {
      document.getElementById('rootDiv').style.backgroundImage = bgImageNColor;
    } else {
      document.getElementById('rootDiv').style.backgroundImage = bgImageNColor;
    }
  };

  const bgLayout = extensionDetails.bgLayout.toLowerCase();
  const rootDivClassname =
    bgLayout === 'leftsidebar' || bgLayout === 'rightsidebar'
      ? 'cta-containerLR'
      : bgLayout === 'popup'
        ? 'cta-containerPopup'
        : bgLayout === 'topbar' || bgLayout === 'bottombar'
          ? 'cta-containerTB'
          : '';

  return (
    <>
      {Object.keys(extensionDetails).length > 0 ? (
        <div
          id="rootDiv"
          className={`cta-container ${rootDivClassname}`}
          style={{ visibility: loaded ? 'visible' : 'hidden' }}>
          <div className="cta-content">
            <div className={`cta-headingContainer ${fontSize}`}>
              {extensionDetails?.headlineBtn === false ? (
                ''
              ) : (
                <div className={`cta-heading ${popupHed ? 'no_padding' : ''}`}>
                  <h2
                    className="cta-headingTag"
                    style={{ color: extensionDetails?.headlineColor }}>
                    {extensionDetails?.headline}
                  </h2>
                </div>
              )}
              {extensionDetails?.subheadlineBtn === false ? (
                ''
              ) : (
                <div
                  className={`cta-subheading ${popupHed ? 'no_padding' : ''}`}>
                  <p
                    className="cta-subheadingTag"
                    style={{ color: extensionDetails?.subHeadlineColor }}>
                    {extensionDetails?.subheadline}
                  </p>
                </div>
              )}
            </div>
            {extensionDetails?.featuredImageBtn === false ? (
              ''
            ) : (
              <div className={`cta-imageContainer ${imageClass} ${fontSize}`}>
                <img
                  className="cta-imageTag"
                  src={extensionDetails.previewImg}
                  onLoad={() => loadedFunction()}
                />
              </div>
            )}
          </div>
          <div className={`cta-buttonContainer ${fontSize}`}>
            {extensionDetails?.ctaBtn === false ? (
              ''
            ) : (
              <a
                href={checkLinkSubstitution(extensionDetails?.targetWebsite)}
                target="_blank"
                type="button" rel="noreferrer">
                <button
                  className={`cta-button ${popupBtn ? 'no_margin' : ''
                    } ${fontSize}`}
                  style={{
                    color: extensionDetails.buttonCaptionColor,
                    backgroundColor: extensionDetails.ctaColour,
                  }}>
                  {extensionDetails?.btnCaption}
                </button>
              </a>
            )}
          </div>
          {!extensionDetails?.ctaBtn &&
            !extensionDetails.headlineBtn &&
            !extensionDetails.subheadlineBtn &&
            !extensionDetails.featuredImageBtn && (
              <div
                className={
                  backgroundWhite
                    ? 'custom-background-font-white'
                    : 'custom-background-font-black'
                }
                style={{ textAlign: 'center' }}>
                No CTA Details Added
              </div>
            )}
        </div>
      ) : null}
    </>
  );
};

export default ExtensionCTA;
