import React from 'react';

const ExtensionMedia = ({ extensionDetails }) => {
    const bgClass = extensionDetails.type.toLowerCase().includes('image')
        ? 'darkGrey'
        : 'bg-light-grey-shade2';

    return (
        <div className={`media-sharingContainer ${bgClass}`}>
            {extensionDetails.type.toLowerCase().includes('image') ? (
                <img src={extensionDetails.url} alt={extensionDetails.name} />
            ) : (
                <div className="download-container">
                    <h3>Click on button to download {extensionDetails.name}</h3>
                    <a
                        href={extensionDetails.downloadURL}
                        download={extensionDetails.name}
                        target="_blank" rel="noreferrer">
                        <button type="button" class="btn-download-pdf btn-green">
                            Download
                        </button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default ExtensionMedia;
