/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoaderEffect } from "../common";
import { init, joinRoom } from "../../libs";
import { useParams } from "react-router-dom";
import { setUserDetail, setRoomDetails, setExtensionDetails, setExtensionShow } from "../../reduxStore/confSlice";
import { socketConnect, addParticipant, getActiveWidget } from "../../utils/socket";
import VideoContainer from "./videoContainer";
import ExtensionHandler from "../extensions/extensionHandler";
import FeaturedChatMessage from "./FeaturedChatMessage";
import { getWidgetCode } from "../../utils/util";

const Conference = () => {
  const { screenDimension, localUser, roomDetail, extensionShow, featuredMsg } = useSelector(
    (state) => state.conf
  );
  const [loaderEffect, setloaderEffect] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const room = params.room.toLowerCase();
  const { viewWidth } = screenDimension;

  // useEffect(() => {
  //   let token = [];
  //   let tmplocalUser = { ...localUser };
  //   Promise.all([socketConnect(room, token), init(dispatch, room)])
  //     .then((ret) => {
  //       if (ret[0]?.status === 200) {
  //         dispatch(setRoomDetails(ret[0]?.data.room));
  //         tmplocalUser = { ...ret[0]?.data.guest };
  //         tmplocalUser.is_hidden = true;
  //         tmplocalUser.name = "Recorder";
  //         dispatch(setUserDetail({ ...tmplocalUser }));
  //         dispatch(setRoomDetails(ret[0]?.data.room));
  //         joinRoom().then(async (jid) => {
  //           tmplocalUser.waiting_status = "accepted";
  //           tmplocalUser.jid = jid;
  //           dispatch(setUserDetail(tmplocalUser));
  //           console.log(tmplocalUser);

  //           Promise.all([addParticipant(tmplocalUser)]).then((ret) => {
  //             setloaderEffect(false);

  //             let payload = { room: roomDetail?.room, token_unique: localUser?.token_unique };
  //             getActiveWidget(payload).then((response) => {
  //               let data = response?.data.length > 0 ? response?.data[0] : {};
  //               if (data && Object.keys(data).length > 0) {
  //                 if (data.extensionid === getWidgetCode('hide-popup')) {
  //                   dispatch(setExtensionShow(false));
  //                   dispatch(setExtensionDetails({}));
  //                 } else {
  //                   dispatch(setExtensionShow(true));
  //                   dispatch(setExtensionDetails(data));
  //                 }
  //               }
  //             });
  //           });
  //         });
  //       } else if (ret[0]?.status === 422) {
  //         console.log("error1", ret[0]?.status);
  //       } else {
  //         console.log("error2", ret[0]?.status);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       console.log("error");
  //     });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    let token = [];
    let tmplocalUser = { ...localUser };
    Promise.all([socketConnect(room, token)])
      .then((ret) => {
        if (ret[0]?.status === 200) {
          let domain = ret[0]?.data?.domain;
          console.log('domain', domain);
          init(dispatch, room, domain).then(() => {
            dispatch(setRoomDetails(ret[0]?.data.room));
            tmplocalUser = { ...ret[0]?.data.guest };
            tmplocalUser.is_hidden = true;
            tmplocalUser.name = "Recorder";
            dispatch(setUserDetail({ ...tmplocalUser }));
            dispatch(setRoomDetails(ret[0]?.data.room));
            joinRoom().then(async (jid) => {
              tmplocalUser.waiting_status = "accepted";
              tmplocalUser.jid = jid;
              dispatch(setUserDetail(tmplocalUser));
              console.log(tmplocalUser);

              Promise.all([addParticipant(tmplocalUser)]).then((ret) => {
                setloaderEffect(false);

                let payload = { room: roomDetail?.room, token_unique: localUser?.token_unique };
                getActiveWidget(payload).then((response) => {
                  let data = response?.data.length > 0 ? response?.data[0] : {};
                  if (data && Object.keys(data).length > 0) {
                    if (data.extensionid === getWidgetCode('hide-popup')) {
                      dispatch(setExtensionShow(false));
                      dispatch(setExtensionDetails({}));
                    } else {
                      dispatch(setExtensionShow(true));
                      dispatch(setExtensionDetails(data));
                    }
                  }
                });
              });
            });
          })

        } else if (ret[0]?.status === 422) {
          console.log("error1", ret[0]?.status);
        } else {
          console.log("error2", ret[0]?.status);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaderEffect ? (
    <LoaderEffect />
  ) : (
    <>
      <div className="conference-wrap">
        <div className={`galleryMain ${roomDetail?.room_background !== '' ? 'room__background' : ''} lem-ext`} style={
          roomDetail?.room_background !== ''
            ? { backgroundImage: `url(${roomDetail?.room_background})` }
            : {}
        }>
          <div
            className="videoGalleryWrapper"
            style={{ width: `${viewWidth}px`, }}
          >
            <VideoContainer />
          </div>

          {extensionShow && <ExtensionHandler />}
          {Object.keys(featuredMsg).length > 0 && featuredMsg.id !== '' && (
            <FeaturedChatMessage />
          )}

        </div>

      </div>
    </>
  );
};
export default Conference;
