import JitsiMeetJS from "./_";
import $ from "jquery";
import { jitsiLocalStorage } from "@jitsi/js-utils";
import { conference } from "../App";
import {
  trackAdd,
  trackRemove,
  setDominantSpeaker,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  setMediaShareParticipants,
  remMediaShareParticipants,
} from "../reduxStore/confSlice";
import { config as defaultConfig } from "./config";
import { store } from "../reduxStore/store";
import { getPartcipiantsUserIds, handleTrackStreamingStatusChanged } from "../utils/functions";
export { JitsiMeetJS as default };
export const browser = JitsiMeetJS.util.browser;
export const JitsiConferenceErrors = JitsiMeetJS.errors.conference;
export const JitsiConferenceEvents = JitsiMeetJS.events.conference;
export const JitsiConnectionErrors = JitsiMeetJS.errors.connection;
export const JitsiConnectionEvents = JitsiMeetJS.events.connection;
export const JitsiConnectionQualityEvents =
  JitsiMeetJS.events.connectionQuality;
export const JitsiDetectionEvents = JitsiMeetJS.events.detection;
export const JitsiE2ePingEvents = JitsiMeetJS.events.e2eping;
export const JitsiMediaDevicesEvents = JitsiMeetJS.events.mediaDevices;
export const JitsiParticipantConnectionStatus =
  JitsiMeetJS.constants.participantConnectionStatus;
export const JitsiTrackStreamingStatus =
  JitsiMeetJS.constants.trackStreamingStatus;
export const JitsiRecordingConstants = JitsiMeetJS.constants.recording;
export const JitsiSIPVideoGWStatus = JitsiMeetJS.constants.sipVideoGW;
export const JitsiTrackErrors = JitsiMeetJS.errors.track;
export const JitsiTrackEvents = JitsiMeetJS.events.track;
export const JitsiMediaDevices = JitsiMeetJS.mediaDevices;
export const createLocalTracks = JitsiMeetJS.createLocalTracks;
let dispatch = null;
let jitsiConnection = null;
let room = null;

let config = { ...defaultConfig };

window.globalUnload = () => {
  room.leave();
  jitsiConnection.disconnect();
};

function unload() {
  setTimeout(() => {
    room.leave();
    jitsiConnection.disconnect();
  }, 1000);
}

$(window).bind("beforeunload", unload);
$(window).bind("unload", unload);

export function GetRoom() {
  return room;
}
export const MEDIA_TYPE = {
  AUDIO: "audio",
  PRESENTER: "presenter",
  SCREENSHARE: "screenshare",
  VIDEO: "video",
};
export function init(d, roomName, domain) {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

  dispatch = d;

  config.hosts.muc = 'conference.' + domain;
  config.hosts.focus = 'focus.' + domain;
  config.hosts.domain = domain;
  config.bosh = 'https://' + domain + '/http-bind';
  config.serviceUrl = 'wss://' + domain + '/xmpp-websocket' + `?room=${roomName}`;
  config.websocketKeepAliveUrl = 'https://' + domain + `?room=${roomName}`;
  config.clientNode = 'https://' + domain;
  config.hiddenDomain = 'recorder.' + domain;
  config.websocket = `wss://${domain}/xmpp-websocket`;

  console.log('config', config, domain, config.serviceUrl);
  JitsiMeetJS.init(config);

  return new Promise((resolve, reject) => {
    startConference(roomName)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function startConference(roomName) {
  // const roomName = room; //getstate().meetn.currentRoom.toLowerCase();
  console.log("webrtc =>", jitsiConnection, roomName);
  roomName = roomName?.toLowerCase();
  return new Promise((resolve, reject) => {
    if (!jitsiConnection) {
      connect(roomName)
        .then((connection) => {
          room = connection.initJitsiConference(roomName, config);
          conference._room = room;
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve();
    }
  });
}

export async function connect(roomName) {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

  let serviceUrl = config.websocket || config.bosh;

  serviceUrl += `?room=${roomName}`;

  config.serviceUrl = serviceUrl;

  if (config.websocketKeepAliveUrl) {
    config.websocketKeepAliveUrl += `?room=${roomName}`;
  }

  JitsiMeetJS.init(config);
  const connection = new JitsiMeetJS.JitsiConnection(null, null, config);
  jitsiConnection = connection;
  return new Promise((resolve, reject) => {
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_ESTABLISHED,
      handleConnectionEstablished
    );
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      handleConnectionFailed
    );

    // connection.addEventListener(
    // JitsiConnectionEvents.CONNECTION_FAILED,
    // connectionFailedHandler);
    let id = "not available";
    let password = "not available";
    const usernameOverride = jitsiLocalStorage.getItem(
      "xmpp_username_override"
    );
    const passwordOverride = jitsiLocalStorage.getItem(
      "xmpp_password_override"
    );

    if (usernameOverride && usernameOverride.length > 0) {
      id = usernameOverride; // eslint-disable-line no-param-reassign
    }
    if (passwordOverride && passwordOverride.length > 0) {
      password = passwordOverride; // eslint-disable-line no-param-reassign
    }
    console.error("chk Pass", id, password);
    connection.connect({
      id,
      password,
    });

    // function connectionFailedHandler(error, message, credentials, details) {
    // /* eslint-enable max-params */
    //     // APP.store.dispatch(
    //     //     connectionFailed(
    //     //         connection, {
    //     //             credentials,
    //     //             details,
    //     //             message,
    //     //             name: error
    //     //         }));

    //     if (isFatalJitsiConnectionError(error)) {
    //         connection.removeEventListener(
    //             JitsiConnectionEvents.CONNECTION_FAILED,
    //             connectionFailedHandler);
    //     }
    // }

    function unsubscribe() {
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_ESTABLISHED,
        handleConnectionEstablished
      );
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_FAILED,
        handleConnectionFailed
      );
    }

    function handleConnectionEstablished() {
      unsubscribe();
      console.log("CONNECTION ESTABLISHED:", connection);
      resolve(connection);
    }

    function handleConnectionFailed(err) {
      unsubscribe();
      console.error("CONNECTION FAILED:", err);
      reject(err);
    }
  });
}

export async function joinRoom() {
  // window.APP.conference._room = room;

  return new Promise((resolve) => {
    roomevents();
    resolve(room.myUserId());
  });
}

const roomevents = () => {
  const { localUser } = store.getState().conf;
  const { name } = localUser;
  room.on(JitsiConferenceEvents.TRACK_ADDED, (track) => {
    if (!track || track.isLocal()) {
      return;
    }
    dispatch(trackAdd(track));
    track.on(JitsiTrackEvents.TRACK_MUTE_CHANGED, (track) => {
      dispatch(trackMuteUpdate(track));
    });
    track.on(JitsiTrackEvents.TRACK_VIDEOTYPE_CHANGED, (type) =>
      dispatch(trackMediaTypeUpdate(track))
    );
    // changeTrackResolution();

    // video stream status update
    if (track.type === 'video') {
      track.on(JitsiTrackEvents.TRACK_STREAMING_STATUS_CHANGED, (t, status) => {
        // console.log('TRACK_STREAMING_STATUS_CHANGED', t?.ownerEndpointId, t, status, t.getTrackStreamingStatus());
        handleTrackStreamingStatusChanged(t, status);
      });
    }
  });
  room.on(JitsiConferenceEvents.TRACK_REMOVED, (track) => {
    dispatch(trackRemove(track));
  });
  room.on(JitsiConferenceEvents.CONFERENCE_JOINED, () => { });

  room.on(JitsiConferenceEvents.USER_JOINED, (_id, _user) => {
    if (_user._displayName === 'Media-Sharing') {
      console.log('USER_JOINED', _id, _user, _user._displayName);
      dispatch(setMediaShareParticipants(_id));
    }
    // dispatch(partcipantAdd(id));
  });
  room.on(JitsiConferenceEvents.USER_LEFT, (_id, _user) => {
    if (_user._displayName === 'Media-Sharing') {
      console.log('USER_LEFT', _id, _user, _user._displayName);
      dispatch(remMediaShareParticipants(_id));
    }
    // dispatch(partcipantRemove(id));
  });

  // room.on(JitsiConferenceEvents.DISPLAY_NAME_CHANGED, (userID, displayName) => {
  //   console.info(`${userID} - ${displayName}`);
  // });

  room.on(
    JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED,
    (id, previousSpeakers) => {
      dispatch(setDominantSpeaker(id));
    }
  );

  room.setReceiverVideoConstraint("720");
  room.join();

  room.setDisplayName(name);
};

export const changeTrackResolution = (participantId) => {
  console.log('changeTrackResolution', participantId);
  let receiverConstraints = {
    constraints: {},
    defaultConstraints: { maxHeight: 180 },
    lastN: -1,
    onStageSources: [],
    selectedSources: [],
  };
  participantId.forEach((p) => {
    receiverConstraints.constraints[p] = { maxHeight: "2160" };
  });
  receiverConstraints.onStageSources = participantId;
  receiverConstraints.selectedSources = participantId;
  console.log("receiverConstraints", receiverConstraints);
  room.setReceiverConstraints(receiverConstraints);
};

export const changeTrackResolutionActive = (participantId) => {
  console.log('changeTrackResolutionActive', participantId);
  const galleryUserIds = getPartcipiantsUserIds();

  const removedDuplicateIds = galleryUserIds.filter(filterItem => !participantId.includes(filterItem));
  const receiverVideoIds = [...participantId, ...removedDuplicateIds];
  let receiverConstraints = {
    constraints: {},
    defaultConstraints: { maxHeight: 180 },
    lastN: -1,
    onStageSources: [],
    selectedSources: [],
  };
  console.log('receiverVideoIds', receiverVideoIds);
  receiverVideoIds.forEach((p, index) => {
    if (index === 0) {
      receiverConstraints.constraints[p] = { maxHeight: '2160' };
    } else {
      receiverConstraints.constraints[p] = { maxHeight: '180' };
    }
  });
  receiverConstraints.onStageSources = [participantId[0]];
  console.log("receiverConstraints", receiverConstraints);
  room.setReceiverConstraints(receiverConstraints);
};
export const changeTrackResolutionSmallActive = (participantId) => {
  console.log('changeTrackResolutionSmallActive', participantId);
  const galleryUserIds = getPartcipiantsUserIds();

  const removedDuplicateIds = galleryUserIds.filter(filterItem => !participantId.includes(filterItem));
  const receiverVideoIds = [...participantId, ...removedDuplicateIds];
  let receiverConstraints = {
    constraints: {},
    defaultConstraints: { maxHeight: 180 },
    lastN: -1,
    onStageSources: [],
    selectedSources: [],
  };
  console.log('receiverVideoIds', receiverVideoIds);
  receiverVideoIds.forEach((p, index) => {
    if (index <= 1) {
      receiverConstraints.constraints[p] = { maxHeight: '2160' };
    } else {
      receiverConstraints.constraints[p] = { maxHeight: '180' };
    }
  });
  receiverConstraints.onStageSources = [participantId[0]];
  console.log("receiverConstraints", receiverConstraints);
  room.setReceiverConstraints(receiverConstraints);
};


