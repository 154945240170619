/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import { useTimer, useStopwatch } from 'react-timer-hook';

const ExtensionTimer = ({ extensionDetails, hideForAll }) => {
    const [timerBackground, setTimerBackground] = useState('');
    const [timerViewMessage, setTimerViewMessage] = useState('Timer');
    const [showTimerLabel, setShowTimerLabel] = useState(false);
    const [showTime, setShowTime] = useState(true);
    const [showLabel, setShowLabel] = useState(true);
    const [showTimer, setShowTimer] = useState(true);

    const timerMessage =
        extensionDetails.goodMessage ||
            extensionDetails.warningMessage ||
            extensionDetails.wrapUpMessage
            ? true
            : false;

    function timerConditions(totalSeconds) {
        if (
            extensionDetails.goodMessage &&
            totalSeconds === extensionDetails.goodSeconds
        ) {
            setTimerBackground('timer-good-bg');
            setTimerViewMessage(extensionDetails.goodText);
        }

        if (
            extensionDetails.warningMessage &&
            totalSeconds === extensionDetails.warningSeconds
        ) {
            setTimerBackground('timer-warn-bg');
            setTimerViewMessage(extensionDetails.warningText);
        }

        if (
            extensionDetails.wrapUpMessage &&
            totalSeconds === extensionDetails.wrapupSeconds
        ) {
            setTimerBackground('timer-wrap-bg');
            setTimerViewMessage(extensionDetails.wrapupText);
        }
    }

    const time = new Date();
    time.setSeconds(time.getSeconds() + extensionDetails.seconds);

    return (
        <div className="meeting_time_container">
            <div
                className={`meeting_time ${timerBackground}`}
                style={{ display: showTimer ? '' : 'none' }}>
                <div className="d-flex justify-content-center">
                    <div style={{ display: showTime ? '' : 'none' }}>
                        {extensionDetails.type.toLowerCase() === 'down' ? (
                            <DownTimer
                                expiryTimestamp={time}
                                extensionDetails={extensionDetails}
                                timerConditions={timerConditions}
                                timerMessage={timerMessage}
                                hideForAll={hideForAll}
                            />
                        ) : (
                            <UpTimer
                                extensionDetails={extensionDetails}
                                timerConditions={timerConditions}
                                timerMessage={timerMessage}
                            />
                        )}
                    </div>
                    <small>{showLabel ? timerViewMessage : ''}</small>
                </div>
            </div>

            <div>
                <a
                    className="meeting_time_menu_btn"
                    onClick={() => {
                        setShowTimerLabel(!showTimerLabel);
                    }}>
                    <svg width="100%" viewBox="10 4 4 16">
                        <path fill="#ffffff" d="M10 10h4v4h-4zm0-6h4v4h-4zm0 12h4v4h-4z" />
                    </svg>
                </a>
                {showTimerLabel && (
                    <div className="meeting_time_menu_options">
                        <label className="checkwrap">
                            Show Time
                            <input
                                spellCheck="false"
                                type="checkbox"
                                id="showTime"
                                name="showTime"
                                onChange={() => {
                                    setShowTime(!showTime);
                                }}
                                checked={showTime}
                            />
                            <span className="checkmark" />
                        </label>
                        <label className="checkwrap">
                            Show Label
                            <input
                                spellCheck="false"
                                type="checkbox"
                                id="showLabel"
                                name="showLabel"
                                onChange={() => {
                                    setShowLabel(!showLabel);
                                }}
                                checked={showLabel}
                            />
                            <span className="checkmark" />
                        </label>
                        <label className="checkwrap">
                            Show Timer
                            <input
                                spellCheck="false"
                                type="checkbox"
                                id="showTimer"
                                name="showTimer"
                                onClick={() => {
                                    setShowTimer(!showTimer);
                                }}
                                checked={showTimer}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

const DownTimer = ({
    expiryTimestamp,
    extensionDetails,
    timerConditions,
    timerMessage,
    hideForAll,
}) => {
    const { seconds, minutes, totalSeconds, start, pause, resume } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn('onExpire called'),
    });

    if (extensionDetails?.hideAtZero && totalSeconds === 0) {
        hideForAll();
    }

    if (timerMessage) {
        timerConditions(totalSeconds);
    }

    const formattedMinutes = minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    const formattedSeconds = seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    const timerStatus = extensionDetails.status.toLowerCase();

    useEffect(() => {
        if (timerStatus === 'start') {
            start();
        } else if (timerStatus === 'pause') {
            pause();
        } else if (timerStatus === 'resume') {
            // const time = new Date();
            // time.setSeconds(
            //   time.getSeconds() + extensionDetails.timer_resume_seconds,
            // );
            // restart(time);
            resume();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerStatus]);

    return (
        <div>
            <div>
                <span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
            </div>
        </div>
    );
};

const UpTimer = ({ extensionDetails, timerConditions, timerMessage }) => {
    const { seconds, minutes, totalSeconds, start, pause } = useStopwatch({
        autoStart: true,
    });

    if (timerMessage) {
        timerConditions(totalSeconds);
    }

    const formattedMinutes = minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    const formattedSeconds = seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    const timerStatus = extensionDetails.status.toLowerCase();

    useEffect(() => {
        if (timerStatus === 'start' || timerStatus === 'resume') {
            start();
        } else if (timerStatus === 'pause') {
            pause();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerStatus]);

    return (
        <div>
            <div>
                <span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
            </div>
        </div>
    );
};

export default memo(ExtensionTimer);
