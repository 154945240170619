export const AVATAR_COLORS = [
    '232, 105, 156',
    '255, 198, 115',
    '128, 128, 255',
    '105, 232, 194',
    '234, 255, 128',
];
export const AVATAR_OPACITY = 0.4;

export const getWidgetCode = widget => {
    const widgetscode = {
        'button-bar': 26999,
        CTA: 66199,
        webpage: 68099,
        timer: 36999,
        'media-share': 52199,
        'hide-popup': 48399,
        'presenter-note': 83799,
    };
    return widgetscode[widget];
};

export const socketURL = 'wss://api.meetn.com';
export const TILE_ASPECT_RATIO = 16 / 9;
export const viewType = {
    active: 'active',
    gallery: 'gallery',
    presentation: 'presentation',
};