// @flow

import React, { Component } from 'react';

class Audio extends Component {
  static defaultProps = {
    autoPlay: true,
    id: '',
  };

  constructor(props) {
    super(props);

    this._setRef = this._setRef.bind(this);
    this._play = this._play.bind(this);
  }

  componentDidMount() {
    this._attachTrack(this.props.audioTrack);

    if (this._ref) {
      const { _muted, _volume } = this.props;

      if (typeof _volume === 'number') {
        this._ref.volume = _volume;
      }

      if (typeof _muted === 'boolean') {
        this._ref.muted = _muted;
      }
    }
  }

  componentWillUnmount() {
    this._detachTrack(this.props.audioTrack);
  }

  shouldComponentUpdate(nextProps) {
    const currentJitsiTrack = this.props.audioTrack?.jitsiTrack;
    const nextJitsiTrack = nextProps.audioTrack?.jitsiTrack;

    if (currentJitsiTrack !== nextJitsiTrack) {
      this._detachTrack(this.props.audioTrack);
      this._attachTrack(nextProps.audioTrack);
    }

    if (this._ref) {
      const currentVolume = this._ref.volume;
      const nextVolume = nextProps._volume;

      if (
        typeof nextVolume === 'number' &&
        !isNaN(nextVolume) &&
        currentVolume !== nextVolume
      ) {
        this._ref.volume = nextVolume;
      }

      const currentMuted = this._ref.muted;
      const nextMuted = nextProps._muted;

      if (typeof nextMuted === 'boolean' && currentMuted !== nextVolume) {
        this._ref.muted = nextMuted;
      }
    }

    return false;
  }

  render() {
    const { autoPlay, id } = this.props;
    console.log('audio js ', autoPlay);
    return <audio key={id} autoPlay={autoPlay} id={id} ref={this._setRef} />;
  }

  _attachTrack(track) {
    if (!track || !track.jitsiTrack) {
      return;
    }

    track.jitsiTrack.attach(this._ref);
    this._play();
  }

  _detachTrack(track) {
    if (this._ref && track && track.jitsiTrack) {
      clearTimeout(this._playTimeout);
      this._playTimeout = undefined;
      track.jitsiTrack.detach(this._ref);
    }
  }

  _play(retries = 0) {
    if (!this._ref) {
      // nothing to play.

      return;
    }
    const { autoPlay, id } = this.props;

    if (autoPlay) {
      // Ensure the audio gets play() called on it. This may be necessary in the
      // case where the local video container was moved and re-attached, in which
      // case the audio may not autoplay.
      this._ref.play().then(
        () => {
          if (retries !== 0) {
            // success after some failures
            this._playTimeout = undefined;
          }
        },
        e => {
          console.error(
            `Failed to play audio track! retry: ${retries} ; Error: ${e}`,
          );

          if (retries < 3) {
            this._playTimeout = setTimeout(() => this._play(retries + 1), 1000);
          } else {
            this._playTimeout = undefined;
          }
        },
      );
    }
  }

  _setRef(audioElement) {
    this._ref = audioElement;
  }
}

export default Audio;
