import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Video, Audio, AttendeeName, Avatar } from "../common";

import {
  setCurrentPage,
  setNumPages,
  setWindowDimension,
  setScreenDimension,
  setGalleryViewParticipants,
  setActiveParticipant,
} from "../../reduxStore/confSlice";
import {
  changeTrackResolution,
  changeTrackResolutionActive,
  changeTrackResolutionSmallActive,
} from "../../libs";
import MediaShareControls from "../extensions/mediaShareControls";
import { ShowActiveSpeakerOnFirstPage, getTrackVideoStatus, isTrackStreamingStatusActive } from "../../utils/functions";
import { TILE_ASPECT_RATIO, viewType } from "../../utils/util";

const VideoContainer = () => {
  const {
    localUser,
    participantsJID,
    dominantSpeaker,
    screenDimension,
    windowDimension,
    chatClick,
    attendeesClick,
  } = useSelector((state) => state.conf);
  const {
    localVideoTrack,
    localDesktopVideoTrack,
    view,
    roomDetail,
    tracks,
    currrentPage,
    maxVideos,
    speaker,
    screenSharedJid,
    mediaShareparticipantsJID,
    mediaControls,
    extensionDetails,
    trackStreamStatus,
  } = useSelector((state) => state.conf);
  const [audioTracks, setAudioTracks] = useState([]);
  // const [videoClass, setvideoClass] = useState('videoSpeaker');
  const [containerView, setcontainerView] = useState("");
  const [smallWindowView, setSmallWindowView] = useState("");
  const [galleryViewParticipantsCount, setGalleryViewParticipantsCount] =
    useState(0);
  const { height, width } = screenDimension;
  const dispatch = useDispatch();

  const showActiveWindow = useMemo(() => {
    let imageShared =
      extensionDetails?.extensionid === 52199 &&
      extensionDetails?.type?.includes("image");
    return (
      roomDetail?.show_speaker_on_presentation &&
      ((view === viewType.presentation && screenSharedJid !== mediaShareparticipantsJID) || imageShared)
    );
  }, [
    roomDetail?.show_speaker_on_presentation,
    view,
    extensionDetails,
    mediaShareparticipantsJID,
    screenSharedJid,
  ]);

  useEffect(() => {
    if (roomDetail?.enable_recent_speaker_feature) {
      ShowActiveSpeakerOnFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dominantSpeaker]);
  useEffect(() => {
    const setWindowSize = () => {
      //  let clientWidth = document.getElementsByClassName('conference-body')?.clientWidth;
      // let clientHeight = document.getElementsByClassName('conference-body')?.clientHeight;
      const { innerHeight, innerWidth } = window;
      // console.log('innerHeight setWindowSize', innerHeight);
      // console.log('innerWidth setWindowSize', innerWidth);

      dispatch(
        setWindowDimension({
          ...{
            clientWidth: innerWidth,
            clientHeight: innerHeight,
          },
        })
      );
    };
    setWindowSize();
    window.addEventListener("resize", setWindowSize);
    return () => {
      window.removeEventListener("resize", setWindowSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (speaker) {
      const allAudioTracks = [];
      Object.keys(participantsJID).forEach((key) => {
        let tmpTrack = tracks.filter(
          (track) => track.type === "audio" && track.participantId === key
        );
        if (tmpTrack.length > 0) {
          allAudioTracks.push(tmpTrack[0]);
        }
      });
      let tmpTrack1 = tracks.filter(
        (track) =>
          track.type === "audio" &&
          track.participantId === mediaShareparticipantsJID
      );
      if (tmpTrack1.length > 0) {
        allAudioTracks.push(tmpTrack1[0]);
      }
      setAudioTracks(allAudioTracks);
    } else {
      setAudioTracks([]);
    }
  }, [tracks, participantsJID, speaker, mediaShareparticipantsJID]);
  useEffect(() => {
    const desktopVideoTrack = getTrack(localDesktopVideoTrack);
    const videoTrack = getTrack(localVideoTrack);

    if (roomDetail?.recorder_view && view !== viewType.presentation) {
      if (roomDetail?.recorder_view === viewType.gallery) {
        setGallery(videoTrack, desktopVideoTrack);
        if (showActiveWindow) {
          setActiveSmallWindow();
        }
      } else if (roomDetail?.recorder_view === viewType.active) {
        setActiveSpeaker(videoTrack);
        if (showActiveWindow) {
          setActiveSmallWindow();
        }
      } else if (roomDetail?.recorder_view === viewType.presentation) {
        setPresentation();
      }
    } else {
      if (view === viewType.gallery) {
        setGallery(videoTrack, desktopVideoTrack);
      } else if (view === viewType.active) {
        setActiveSpeaker(videoTrack);
      } else if (view === viewType.presentation) {
        setPresentation();
        if (showActiveWindow) {
          setActiveSmallWindow();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    view,
    width,
    height,
    tracks,
    localVideoTrack,
    localDesktopVideoTrack,
    participantsJID,
    currrentPage,
    maxVideos,
    dominantSpeaker,
    roomDetail,
    localUser?.profile_image,
    trackStreamStatus,
  ]);

  useEffect(() => {
    dispatch(calculateGallerySize(galleryViewParticipantsCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    galleryViewParticipantsCount,
    windowDimension,
    chatClick,
    attendeesClick,
  ]);

  const getvideoClass = (count) => {
    let videoclass = "videoSpeaker";
    videoclass = count === 2 ? "videoTwo" : videoclass;
    videoclass = count >= 3 && count <= 4 ? "videoUptoFour" : videoclass;
    videoclass = count >= 5 && count <= 6 ? "videoUptoSix" : videoclass;
    videoclass = count >= 7 && count <= 9 ? "videoUptoNine" : videoclass;
    videoclass = count >= 10 && count <= 12 ? "videoUptoTwelve" : videoclass;
    videoclass = count >= 13 && count <= 16 ? "videoUptoSixteen" : videoclass;
    videoclass = count >= 17 && count <= 20 ? "videoUptoTwenty" : videoclass;
    videoclass =
      count >= 21 && count <= 24 ? "videoUptoTwentyFour" : videoclass;

    return videoclass;
  };
  const setGallery = (videoTrack, desktopVideoTrack) => {
    const tmpParticipantsTracks = [];
    const participantSelectedTracks = [];
    const participantId = [];
    let tmpParticipantsJID = { ...participantsJID };

    console.log("setGallery tmpParticipantsJID", tmpParticipantsJID);
    Object.keys(tmpParticipantsJID).forEach((key) => {
      if (roomDetail.hide_muted_attendees) {
        if (tmpParticipantsJID[key].audio) {
          const remoteVideoTrack = tracks.filter(
            (track) =>
              track.participantId === key &&
              track.type === "video" &&
              track.mediaType === "camera" &&
              track.muted === false
          );
          if (remoteVideoTrack.length > 0) {
            const participant = {
              track: remoteVideoTrack[0].jitsiTrack,
              type: "camera",
              name: tmpParticipantsJID[key].name,
              profile_image: tmpParticipantsJID[key]?.profile_image,
              jid: key,
              local: false,
            };
            tmpParticipantsTracks.push(participant);
          } else {
            const participant = {
              track: null,
              type: "camera",
              name: tmpParticipantsJID[key].name,
              profile_image: tmpParticipantsJID[key]?.profile_image,
              jid: key,
              local: false,
            };
            tmpParticipantsTracks.push(participant);
          }
        }
      } else {
        const remoteVideoTrack = tracks.filter(
          (track) =>
            track.participantId === key &&
            track.type === "video" &&
            track.mediaType === "camera" &&
            track.muted === false
        );
        if (remoteVideoTrack.length > 0) {
          const participant = {
            track: remoteVideoTrack[0].jitsiTrack,
            type: "camera",
            name: tmpParticipantsJID[key].name,
            profile_image: tmpParticipantsJID[key]?.profile_image,
            jid: key,
            local: false,
          };
          tmpParticipantsTracks.push(participant);
        } else {
          const participant = {
            track: null,
            type: "camera",
            name: tmpParticipantsJID[key].name,
            profile_image: tmpParticipantsJID[key]?.profile_image,
            jid: key,
            local: false,
          };
          tmpParticipantsTracks.push(participant);
        }
      }
      const remoteDesktopTrack = tracks.filter(
        (track) =>
          track.participantId === key &&
          track.type === "video" &&
          track.mediaType === "desktop" &&
          track.muted === false
      );

      if (remoteDesktopTrack.length > 0) {
        const participant = {
          track: remoteDesktopTrack[0].jitsiTrack,
          type: "desktop",
          name: tmpParticipantsJID[key].name + "&#39;s Presentation",
          profile_image: tmpParticipantsJID[key]?.profile_image,
          jid: key,
          local: false,
        };
        tmpParticipantsTracks.push(participant);
      }
    });
    console.log("initial tmpParticipantsTracks", tmpParticipantsTracks);

    let tmpParticipantsLength =
      tmpParticipantsTracks.length + participantSelectedTracks.length;
    console.log("initial tmpParticipantsLength", tmpParticipantsLength);
    const numPages = Math.ceil(tmpParticipantsLength / maxVideos);

    dispatch(setNumPages(numPages));
    console.log("initial currrentPage", currrentPage);
    console.log("initial numPages", numPages);
    if (tmpParticipantsLength > 0) {
      if (currrentPage > numPages) {
        dispatch(setCurrentPage(currrentPage - 1));
      } else {
        let startCount = currrentPage * maxVideos;
        startCount = startCount - maxVideos;
        let endCount =
          currrentPage * maxVideos - participantSelectedTracks.length;
        if (startCount >= maxVideos) {
          startCount = startCount - participantSelectedTracks.length;
        }
        console.log("startCount", startCount);
        console.log("endCount", endCount);

        for (
          let i = startCount;
          i < endCount && i < tmpParticipantsTracks.length;
          i++
        ) {
          participantSelectedTracks.push(tmpParticipantsTracks[i]);
          if (
            tmpParticipantsTracks[i]?.track &&
            !tmpParticipantsTracks[i]?.local
          ) {
            participantId.push(tmpParticipantsTracks[i].track.getSourceName());
          }
        }

        changeTrackResolution(participantId);
        const count = participantSelectedTracks.length;

        console.log("numPages=>", numPages);
        console.log("currrentPage=>", currrentPage);
        console.log("participantSelectedTracks=>", participantSelectedTracks);
        console.log("count=>", count);
        setcontainerView(
          getgalleryContainer(participantSelectedTracks, getvideoClass(count))
        );
        setGalleryViewParticipantsCount(count);

        dispatch(setGalleryViewParticipants(participantSelectedTracks));
      }
    } else {
      setcontainerView(getgalleryContainer(participantSelectedTracks, 0));
      setGalleryViewParticipantsCount(0);
      dispatch(setGalleryViewParticipants(participantSelectedTracks));
    }
  };
  const setActiveSpeaker = (videoTrack) => {
    let track = null;
    let name = null;
    let tmpParticipant = localUser;
    console.log("setActiveSpeaker dominantSpeaker", dominantSpeaker);
    if (participantsJID[dominantSpeaker]) {
      tmpParticipant = participantsJID[dominantSpeaker];
      let trackArray = tracks.filter(
        (t) =>
          t.participantId === dominantSpeaker &&
          t.type === "video" &&
          t.mediaType === "camera" &&
          t.muted === false
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
        changeTrackResolutionActive([track.jitsiTrack.getSourceName()]);
      } else {
        track = null;
      }
      name = participantsJID[dominantSpeaker].name;
    }
    if (tmpParticipant.audio) {
      setGalleryViewParticipantsCount(1);
      setcontainerView(
        getActiveSpeakerContainer(track, name, getvideoClass(1), tmpParticipant)
      );
      dispatch(setActiveParticipant(tmpParticipant));
    } else {
      setcontainerView(
        getActiveSpeakerContainer(track, name, 0, tmpParticipant)
      );
      setGalleryViewParticipantsCount(0);
      dispatch(setActiveParticipant(tmpParticipant));
    }
  };
  const setPresentation = () => {
    let track = null;
    let name = null;

    const participent = participantsJID[screenSharedJid];
    if (participent) {
      console.log("setPresentation", screenSharedJid, participent);
      let trackArray = tracks.filter(
        (t) =>
          t.participantId === screenSharedJid &&
          t.type === "video" &&
          t.mediaType === "desktop" &&
          t.muted === false
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
        if (!showActiveWindow) {
          changeTrackResolutionActive([track.jitsiTrack.getSourceName()]);
        }
      }
      name = participantsJID[screenSharedJid].name + "&#39;s Presentation";
    } else if (mediaShareparticipantsJID === screenSharedJid) {
      let trackArray = tracks.filter(
        (t) =>
          t.participantId === screenSharedJid &&
          t.type === "video" &&
          t.mediaType === "camera" &&
          t.muted === false
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
        if (!showActiveWindow) {
          changeTrackResolutionActive([track.jitsiTrack.getSourceName()]);
        }
      }
    }
    console.log('setPresentation', track, name);
    setGalleryViewParticipantsCount(1);
    setcontainerView(
      getpresenterContainer(track, name, getvideoClass(1), participent)
    );
  };

  const setActiveSmallWindow = (videoTrack) => {
    let track = null;
    let name = null;
    let tmpParticipant = localUser;
    let userids = [];
    console.log("setActiveSmallWindow", dominantSpeaker);
    if (participantsJID[dominantSpeaker]) {
      tmpParticipant = participantsJID[dominantSpeaker];
      let trackArray = tracks.filter(
        (t) =>
          t.participantId === dominantSpeaker &&
          t.type === "video" &&
          t.mediaType === "camera" &&
          t.muted === false
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
        userids.unshift(track.jitsiTrack.getSourceName());
      } else {
        track = null;
      }

      let screenShareTracks = tracks.filter(
        (t) =>
          t.participantId === screenSharedJid &&
          t.type === "video" &&
          t.mediaType === "desktop" &&
          t.muted === false
      );
      if (screenShareTracks.length > 0) {
        userids.unshift(screenShareTracks[0].jitsiTrack.getSourceName());
      }
      changeTrackResolutionSmallActive(userids);

      name = participantsJID[dominantSpeaker].name;
    } else {
      let screenShareTracks = tracks.filter(
        (t) =>
          t.participantId === screenSharedJid &&
          t.type === "video" &&
          t.mediaType === "desktop" &&
          t.muted === false
      );
      if (screenShareTracks.length > 0) {
        userids.unshift(screenShareTracks[0].jitsiTrack.getSourceName());
        changeTrackResolutionSmallActive(userids);
      }
    }
    if (tmpParticipant.audio) {
      setSmallWindowView(
        getActiveSmallContainer(track, name, getvideoClass(1), tmpParticipant)
      );
    } else {
      setSmallWindowView(
        getActiveSmallContainer(track, name, 0, tmpParticipant)
      );
    }
  };
  const getgalleryContainer = (participantSelectedTracks, videoClass) => {
    return (
      <>
        {participantSelectedTracks.length > 0 ? (
          participantSelectedTracks.map((participant) => {
            let speakerClass = "";
            if (
              participant.jid === dominantSpeaker &&
              participantSelectedTracks.length > 1
            ) {
              speakerClass = "highlighted-box";
            }
            let darkBackground = "darkGrey";
            if (participant.track) {
              darkBackground = "";
            }
            let isActive = isTrackStreamingStatusActive(
              participant?.track,
              trackStreamStatus,
            );
            let currentStatus = getTrackVideoStatus(participant?.track);
            console.log('isActive', isActive);
            return (
              <div
                className={`videoBlock ${videoClass} ${speakerClass} ${darkBackground}`}
                style={{ width: `${width}px`, height: `${height}px` }}
                key={participant.jid + "_" + participant.type + "_partent"}
              >
                <div
                  className="videoBlockInner"
                  key={participant.jid + "_" + participant.type + "_child1"}
                >
                  {participant.track && isActive ? (
                    <>
                      {videoElement(participant.track)}
                      {/* When video is visible */}

                      <AttendeeName
                        name={participant.name}
                        key={`${participant.jid}_${participant.type}_child2`}
                      />

                      {/* When video is off */}
                    </>
                  ) : (
                    <Avatar
                      participant={participant}
                      key={participant.jid + "_" + participant.type + "_child2"}
                      showWarning={!isActive}
                      streamStatus={currentStatus}
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <span className="white stand-by">
            Please stand by until someone is unmuted.
          </span>
        )}
      </>
    );
  };
  const getpresenterContainer = (videoTrack, name, videoClass, participant) => {
    return (
      <>
        <div
          className={`videoBlock ${videoClass}`}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <div className="videoBlockInner">
            {videoTrack !== null ? (
              <>
                {videoElement(videoTrack.jitsiTrack)}
                {/* When video is visible */}
                {name && <AttendeeName name={name} />}
                {/* When video is off */}
              </>
            ) : (
              <>{/* <Avatar participant={participant} /> */}</>
            )}
          </div>
        </div>
      </>
    );
  };
  const getActiveSpeakerContainer = (videoTrack, name, videoClass, p) => {
    let darkBackground = "darkGrey";
    if (videoTrack) {
      darkBackground = "";
    }
    let isActive = isTrackStreamingStatusActive(
      videoTrack?.jitsiTrack,
      trackStreamStatus,
    );

    return (
      <>
        {p.audio ? (
          <div
            className={`videoBlock ${videoClass} ${darkBackground}`}
            style={{ width: `${width}px`, height: `${height}px` }}
          >
            <div className="videoBlockInner">
              {videoTrack !== null && isActive ? (
                <>
                  {videoElement(videoTrack.jitsiTrack)}
                  {/* When video is visible */}
                  <AttendeeName name={name} />
                  {/* When video is off */}
                </>
              ) : (
                <Avatar
                  participant={p}
                />
              )}
            </div>
          </div>
        ) : (
          <span className="white stand-by">
            Please stand by until someone speaks.
          </span>
        )}
      </>
    );
  };
  const getActiveSmallContainer = (videoTrack, name, videoClass, p) => {
    let darkBackground = "darkGrey";
    if (videoTrack) {
      darkBackground = "";
    }
    let isActive = isTrackStreamingStatusActive(
      videoTrack?.jitsiTrack,
      trackStreamStatus,
    );

    return (
      <>
        {p.audio ? (
          <div
            className={`videoBlock ${videoClass} ${darkBackground}`}
          // style={{ width: `${width}px`, height: `${height}px` }}
          >
            <div className="videoBlockInner">
              {videoTrack !== null && isActive ? (
                <>
                  {videoElement(videoTrack.jitsiTrack)}
                  {/* When video is visible */}
                  <AttendeeName name={name} />
                  {/* When video is off */}
                </>
              ) : (
                <Avatar participant={p}

                />
              )}
            </div>
          </div>
        ) : (
          <div className={`videoBlock ${videoClass} ${darkBackground}`}>
            <span className="white stand-by small-view">
              Please stand by until someone speaks.
            </span>
          </div>
        )}
      </>
    );
  };

  console.log("showActiveWindow", showActiveWindow);
  return (
    <>
      {audioTracks.map((track) => {
        return AudioContainer(track.jitsiTrack, track.volume);
      })}

      {containerView}

      {showActiveWindow && (
        <>
          <div className="small-active-window">{smallWindowView}</div>
        </>
      )}
      {/* {mediaShareparticipantsJID &&
        ((mediaControls?.type?.includes("video") &&
          view === viewType.presentation) ||
          mediaControls?.type?.includes("audio")) && <MediaShareControls />} */}
    </>
  );
};
export default VideoContainer;
const videoElement = (track) => {
  let videoTrack = { jitsiTrack: track };

  return (
    <Video
      key={`video_${track.getId()}`}
      id={`video_${track.getId()}`}
      videoTrack={videoTrack}
    />
  );
};

const AudioContainer = (localAudioTrack, volume) => {
  let audioTrack = { jitsiTrack: localAudioTrack };
  return (
    <Audio
      key={`audio_${localAudioTrack.getId()}`}
      audioTrack={audioTrack}
      _volume={volume}
    />
  );
};

const getTrack = (track) => {
  return track !== null && track?.muted === false ? track : null;
};

const calculateGallerySize = (participants) => {
  return (dispatch, getState) => {
    const state = getState();
    const { screenDimension, windowDimension } = state.conf;

    let { clientWidth, clientHeight } = windowDimension;
    let { columns, minVisibleRows, maxColumns = 6, rows } = screenDimension;
    let height = 0,
      width = 0,
      viewWidth = 0,
      screenWidth = 0,
      screenHeight = 0;

    const columnsToMaintainASquare = Math.ceil(Math.sqrt(participants));
    columns = Math.min(columnsToMaintainASquare, maxColumns);
    rows = Math.ceil(participants / columns);
    minVisibleRows = Math.min(maxColumns, rows);

    // navBarHeight = document.getElementById("conference-nav-bar")?.clientHeight;
    // btmBarHeight = document.getElementById('free-forever-btmBr')?.clientHeight

    // const sideMargins = 30 * 2;
    //const sideMargins = 0

    viewWidth = clientWidth;
    screenWidth = clientWidth;
    screenHeight = clientHeight;

    const initialWidth = viewWidth / columns;
    const aspectRatioHeight = initialWidth / TILE_ASPECT_RATIO;

    const initialHeight = screenHeight / minVisibleRows;

    height = Math.floor(Math.min(aspectRatioHeight, initialHeight));
    width = Math.floor(TILE_ASPECT_RATIO * height);
    console.log("setScreenDimension", screenDimension, viewWidth);
    dispatch(
      setScreenDimension({
        ...screenDimension,
        ...{
          columns,
          rows,
          minVisibleRows,
          height,
          width,
          viewWidth,
          screenHeight,
          screenWidth,
        },
      })
    );
  };
};
