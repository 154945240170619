import React from 'react';
import { useSelector } from 'react-redux';

function AttendeeName({ name, key }) {
    const { roomDetail } = useSelector(state => state.conf);

    console.log('show_names_on_videos', roomDetail?.show_names_on_videos)
    return (
        <>
            {
                roomDetail?.show_names_on_videos && <div
                    className="blur-div-left"
                    key={key || ''}
                    dangerouslySetInnerHTML={{ __html: name }}
                />
            }
        </>

    );

}

export default AttendeeName;
