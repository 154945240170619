/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-void */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackVolumeUpdate } from '../../reduxStore/confSlice';

function MediaShareControls() {

    const { mediaControls, roomDetail, mediaShareparticipantsJID } = useSelector(state => state.conf);
    const backwardButtonRef = useRef('');
    const forwardButtonRef = useRef('');
    const { play, mute, volume: vol } = mediaControls;
    const dispatch = useDispatch();

    const [volume, setVolume] = useState(0);

    useEffect(() => {
        setVolume(vol);
        handleVolume();
    }, [vol]);

    const manageMediaControls = (obj) => {

    };

    const onMouseUpHandler = () => {
        if (volume > 0) {
            manageMediaControls({ mute: false, volume });
        }
        if (volume === 0) {
            manageMediaControls({ mute: true, volume });
        }
    };

    const manageMediaEffect = (action) => {
        let actionClass;

        if (action === 'backward') {
            actionClass = 'time_limit_backward_container';

            backwardButtonRef.current.classList.add(actionClass);

            setTimeout(() => {
                backwardButtonRef.current.classList.remove(actionClass);
            }, 800);
        }

        if (action === 'forward') {
            actionClass = 'time_limit_forward_container';

            forwardButtonRef.current.classList.add(actionClass);

            setTimeout(() => {
                forwardButtonRef.current.classList.remove(actionClass);
            }, 800);
        }
    };

    const handleVolume = () => {
        dispatch(trackVolumeUpdate({ participantId: mediaShareparticipantsJID, volume: vol }));
    };


    return (
        <>
            {
                roomDetail?.guests_have_extra_powers ? <div className="media-sharing-icons-container">
                    <a
                        href={void (0)}
                        onClick={() => manageMediaControls({ play: !play })}
                    >

                        {play ? (
                            <svg width=".9em" viewBox="8 7 8 10">
                                <path d="M8 7h3v10H8zm5 0h3v10h-3z" />
                            </svg>
                        ) : (
                            <svg width=".9em" viewBox="7 6 10 12">
                                <path d="M7 6v12l10-6z" />
                            </svg>
                        )}

                    </a>
                    <a
                        href={void (0)}
                        onClick={(e) => {
                            manageMediaControls({ backward: true });
                            manageMediaEffect('backward');
                        }}
                        ref={backwardButtonRef}

                    >
                        <svg
                            width="1.1em"
                            className="rotateY--180"
                            viewBox="3.44 2 17.13 20"
                        >
                            <path d="M19.89 10.105a8.696 8.696 0 0 0-.789-1.456l-1.658 1.119a6.606 6.606 0 0 1 .987 2.345 6.659 6.659 0 0 1 0 2.648 6.495 6.495 0 0 1-.384 1.231 6.404 6.404 0 0 1-.603 1.112 6.654 6.654 0 0 1-1.776 1.775 6.606 6.606 0 0 1-2.343.987 6.734 6.734 0 0 1-2.646 0 6.55 6.55 0 0 1-3.317-1.788 6.605 6.605 0 0 1-1.408-2.088 6.613 6.613 0 0 1-.382-1.23 6.627 6.627 0 0 1 .382-3.877A6.551 6.551 0 0 1 7.36 8.797 6.628 6.628 0 0 1 9.446 7.39c.395-.167.81-.296 1.23-.382.107-.022.216-.032.324-.049V10l5-4-5-4v2.938a8.805 8.805 0 0 0-.725.111 8.512 8.512 0 0 0-3.063 1.29A8.566 8.566 0 0 0 4.11 16.77a8.535 8.535 0 0 0 1.835 2.724 8.614 8.614 0 0 0 2.721 1.833 8.55 8.55 0 0 0 5.061.499 8.576 8.576 0 0 0 6.162-5.056c.22-.52.389-1.061.5-1.608a8.643 8.643 0 0 0 0-3.45 8.684 8.684 0 0 0-.499-1.607z" />
                        </svg>
                        <span className="time_limit">10</span>
                    </a>
                    <a
                        href={void (0)}
                        onClick={(e) => {
                            manageMediaControls({ forward: true });
                            manageMediaEffect('forward');
                        }}
                        ref={forwardButtonRef}
                    >
                        <svg width="1.1em" viewBox="3.44 2 17.13 20">
                            <path d="M19.89 10.105a8.696 8.696 0 0 0-.789-1.456l-1.658 1.119a6.606 6.606 0 0 1 .987 2.345 6.659 6.659 0 0 1 0 2.648 6.495 6.495 0 0 1-.384 1.231 6.404 6.404 0 0 1-.603 1.112 6.654 6.654 0 0 1-1.776 1.775 6.606 6.606 0 0 1-2.343.987 6.734 6.734 0 0 1-2.646 0 6.55 6.55 0 0 1-3.317-1.788 6.605 6.605 0 0 1-1.408-2.088 6.613 6.613 0 0 1-.382-1.23 6.627 6.627 0 0 1 .382-3.877A6.551 6.551 0 0 1 7.36 8.797 6.628 6.628 0 0 1 9.446 7.39c.395-.167.81-.296 1.23-.382.107-.022.216-.032.324-.049V10l5-4-5-4v2.938a8.805 8.805 0 0 0-.725.111 8.512 8.512 0 0 0-3.063 1.29A8.566 8.566 0 0 0 4.11 16.77a8.535 8.535 0 0 0 1.835 2.724 8.614 8.614 0 0 0 2.721 1.833 8.55 8.55 0 0 0 5.061.499 8.576 8.576 0 0 0 6.162-5.056c.22-.52.389-1.061.5-1.608a8.643 8.643 0 0 0 0-3.45 8.684 8.684 0 0 0-.499-1.607z" />
                        </svg>
                        <span className="time_limit time_limit_forward">
                            10
                        </span>
                    </a>
                    <a
                        href={void (0)}
                        onClick={() => manageMediaControls({ mute: !mute })}
                    >
                        {!mute ? (
                            <svg width="1.2em" viewBox="2 2.13 20 19.74">
                                <path d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z" />
                                <path d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697L14 21.868V2.132L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2z" />
                            </svg>
                        ) : (
                            <svg width="1.2em" viewBox="2 2.13 20 19.74">
                                <path d="m7.727 6.313-4.02-4.02-1.414 1.414 18 18 1.414-1.414-2.02-2.02A9.578 9.578 0 0 0 21.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.13 8.13 0 0 1-1.671 4.914l-1.286-1.286C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V2.132L7.727 6.313zM4 17h2.697L14 21.868v-3.747L3.102 7.223A1.995 1.995 0 0 0 2 9v6c0 1.103.897 2 2 2z" />
                            </svg>
                        )}
                    </a>
                    <div className="slider_range">
                        <input className="slider_range_input" spellCheck="false" type="range" min={0} max={1} step={0.02} value={volume} onChange={(event) => setVolume(event.target.valueAsNumber)} onMouseUp={onMouseUpHandler} />
                    </div>
                </div> : ''
            }

        </>
    );
}

export default MediaShareControls;
