import { io } from "socket.io-client";
import { store } from "../reduxStore/store";
import {
  setRoomDetails,
  setSocketConnected,
  setParticipantsList,
  updParticipantsList,
  remParticipantsList,
  setExtensionShow,
  setExtensionDetails,
  setFeatureMsg,
  setMediaControls,
} from "../reduxStore/confSlice";
import { getWidgetCode, socketURL } from "./util";

let socket = null;
const { dispatch } = store;

export const socketConnect = (room, token) => {
  return new Promise((resolve) => {
    socket = io.connect(socketURL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99999,
    });

    socket.on("connect", () => {
      if (socket.connected) {
        socket.emit(
          "con",
          {
            room,
            token,
          },
          (response) => {
            dispatch(setSocketConnected(true));
            console.log("con response", response);
            return resolve(response);
          }
        );
      }
    });

    ///

    socket.io.on("ping", () => {
      console.log("socket.ping");
    });

    socket.on("disconnect", () => {
      console.log("socket.disconnect");
    });

    socket.io.on("reconnect", (attempt) => {
      console.log("socket.reconnect", attempt);
    });

    socket.io.on("reconnect_attempt", (attempt) => {
      console.log("socket.reconnect_attempt", attempt);
    });

    socket.io.on("reconnect_error", (error) => {
      console.log("socket.reconnect_error", error);
      return resolve({ status: 500, data: "socket.reconnect_error" });
    });

    socket.io.on("reconnect_failed", () => {
      console.log("socket.reconnect_failed");
      return resolve({ status: 500, data: "socket.reconnect_failed" });
    });

    ///
    socket.on("all", (data) => {
      dispatch(setParticipantsList(data));
    });

    socket.on("upd", (data) => {
      dispatch(updParticipantsList(data));
    });

    socket.on("add", (data) => {
      dispatch(updParticipantsList(data));
    });

    socket.on("rem", (data) => {
      dispatch(remParticipantsList(data));
    });

    socket.on("room-settings", (data) => {
      const state = store.getState();
      const { roomDetail } = state.conf;
      const tempRoomDetail = { ...roomDetail, ...data };
      console.log('on room-settings', tempRoomDetail?.recording_view);
      dispatch(setRoomDetails(tempRoomDetail));
    });

    //extensions
    socket.on('show', doc => {

      console.log('extensions - show', doc);
      if (doc.extensionid === getWidgetCode('hide-popup')) {
        dispatch(setExtensionShow(false));
        dispatch(setExtensionDetails({}));
      } else {
        dispatch(setExtensionShow(true));
        dispatch(setExtensionDetails(doc));
      }
    });

    socket.on('hide', doc => {
      console.log('extensions - hide', doc);
      dispatch(setExtensionShow(false));
      dispatch(setExtensionDetails({}));
    });

    socket.on('msg-featured', doc => {
      dispatch(setFeatureMsg(doc));
    });

    socket.on('msg-unfeatured', () => {
      dispatch(setFeatureMsg({}));
    });

    socket.on('media-set', doc => {
      console.log('media-set', doc);
      dispatch(setMediaControls(doc));
    });
  });
};

///

export const addParticipant = (doc) => {
  return new Promise((resolve) => {
    socket.emit("add", doc, (res) => {
      return resolve(res);
    });
  });
};

export const remParticipant = (doc) => {
  return new Promise((resolve) => {
    socket.emit("rem", doc, (res) => {
      return resolve(res);
    });
  });
};

///

export const sendChatMessage = (doc) => {
  return new Promise((resolve) => {
    socket.emit("msg", doc, (res) => {
      return resolve(res);
    });
  });
};

export const deleteChatMessage = (doc) => {
  return new Promise((resolve) => {
    socket.emit("msg-delete", doc, (res) => {
      return resolve(res);
    });
  });
};

export const featureChatMessage = (doc) => {
  return new Promise((resolve) => {
    socket.emit("msg-featured", doc, (res) => {
      return resolve(res);
    });
  });
};

export const unfeatureChatMessage = (doc) => {
  return new Promise((resolve) => {
    socket.emit("msg-unfeatured", doc, (res) => {
      return resolve(res);
    });
  });
};

export const disposeMeetnSocketConnection = () => {
  if (socket) {
    socket.disconnect();
  }
};

export const getActiveWidget = doc => {
  return new Promise(resolve => {
    socket.emit('widget-active', doc, res => {
      return resolve(res);
    });
  });
};