/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { viewType } from "../utils/util";

export const confSlice = createSlice({
  name: "conf",
  initialState: {
    localAudioTrack: null,
    localVideoTrack: null,
    localDesktopVideoTrack: null,
    localDesktopAudioTrack: null,
    mediaPermissionPrompt: false,
    screenShared: false,
    view: viewType.gallery,
    lastView: viewType.gallery,
    maxVideos: 24,
    dominantSpeaker: null,
    numPages: 1,
    currrentPage: 1,
    screenSharedJid: null,
    prevScreenSharedJid: null,
    ///
    localUser: {},
    roomDetail: {},
    guestDetail: {},
    ///
    localParticipantData: null,
    remoteParticipantIds: [],
    room: undefined,
    ///
    participantsJID: [],
    participantsList: [],
    participantsUnmuted: [],
    participantsGuest: [],
    participantsHost: [],
    participantsWaiting: [],
    participantsScreenShared: [],
    mediaShareparticipantsJID: null,
    ///
    localParticipantDetail: {},
    gallery_participants: "",
    dominant: "",
    selectLanguage: "en",
    isBackButtonShow: false,
    clickForgotPassword: false,
    chatMessage: [],
    featuredMsg: {},
    currentRoom: "",
    muteAllLoader: false,
    unmuteAllLoader: false,
    myRole: "",
    openLoginModel: false,
    orientation: "PORTRAIT",
    chatClick: false,
    attendeesClick: false,
    guestsUnmuteLoader: false,
    recordingViewValue: "active",
    extensionList: [],
    isRequestDeclined: false,
    mutedAttendeesLoader: false,
    waitingStatusLoader: false,
    breakoutRoomJoined: false,
    openRecordingModal: false,
    mic: false,
    camera: false,
    speaker: true,
    raise: false,
    waitingRoomLoaders: {
      accept: [],
      decline: [],
    },
    waitingRoomPopup: false,
    waitingEnabled: false,
    exitModal: false,
    feedbackPopUp: false,
    participantsLoaders: {
      audio: [],
      video: [],
      hand_raised: [],
    },
    conferenceConnecting: false,
    muteUnmutePermission: false,
    openStreamManageModal: false,
    mouseOverUser: "",
    openUpgradePlanModal: false,
    waitingRoomModal: false,
    hostRequiredModal: false,
    videoPlayModal: false,
    unreadMessageCount: 0,
    showMenu: false,
    showMenuType: "",
    showRecording: false,
    showStreaming: false,
    shareScreen: false,
    openStopRecordingModal: false,
    isRecordingStart: false,
    recordingDuration: 0,
    recordingStopLoader: false,
    recordingStartLoader: false,
    streamingStarted: false,
    startStreamingLoader: false,
    stopStreamingLoader: false,
    addDestinationType: "custom",
    ratingPopUp: false,
    socketConnected: false,
    recordingData: {},
    recordingName: "",
    streamingTitleDescription: {
      title: "",
      description: "",
      display_social_media_chat: "",
    },
    streamingDestinations: [],
    timeCal: 0,
    intval: null,
    hours: 0,
    minutes: 0,
    seconds: 0,
    streamingData: {},
    streamingDuration: 0,
    destinationsList: [],
    streamingErrors: {},
    destinationListLoader: false,
    maxDestination: false,
    withoutChoosingDestination: false,
    streamTimeCal: 0,
    streamIntval: null,
    streamHours: 0,
    streamMinutes: 0,
    streamSeconds: 0,
    waitingRoomCycle: false,
    waitingRoomTitle: "",
    screenDimension: {},
    windowDimension: {},
    page: "JoinFlow",
    previousRouteStep: "",
    loginAsGuest: false,
    audioInputList: [],
    videoInputList: [],
    audioOutputList: [],
    cameraDevice: null,
    micDevice: null,
    speakerDevice: null,
    tracks: [],
    participants: {},
    hasAudioPermission: false,
    hasVideoPermission: false,
    hasAudioEnable: false,
    hasVideoEnable: false,
    joinWithVideo: false,
    loaderEffect: false,
    waitingTitleInterval: null,
    showInviteModal: false,
    showAvSettingModal: false,
    showEditProfileModal: false,
    showPhoneCommandModal: false,
    showWebinarSettingModal: false,
    showRoomSettingModal: false,
    showMeetingModal: false,
    showRoomPermissionModal: false,
    showShareWidgetModal: false,
    showManageRoomModal: false,
    showInvoiceModal: false,
    showBillingInfoModal: false,

    extensionShow: false,
    extensionDetails: {},
    mediaControls: {},
    galleryViewParticipants: [],
    trackStreamStatus: {},
    activeParticipant: {},
  },

  reducers: {
    setCurrentPage: (state, action) => {
      state.currrentPage = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setDominantSpeaker: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    ///
    setSocketConnected: (state, action) => {
      state.socketConnected = action.payload;
    },
    setParticipantsList: (state, action) => {
      // console.log('confSlice setParticipantsList', action.payload);
      let tmpParticipantsJID = {};
      let tmpParticipantsList = {};
      let tmpParticipantsUnmuted = {};
      let tmpParticipantsGuest = {};
      let tmpParticipantsHost = {};
      let tmpParticipantsWaiting = {};
      let tmpparticipantsScreenShared = {};
      let screenSharedJid = state.screenSharedJid;
      let interactiveParticipants = {};

      let prevInteractiveParticipantsIds = Object.keys(state.participantsJID);

      action.payload.forEach((val) => {
        if (!val?.is_hidden) {
          if (val.token_unique === state.localUser.token_unique) {
            state.localUser = val;
          } else {
            if (val.waiting_status === "accepted" && val.interaction_type === 'interactive') {
              tmpParticipantsJID[val.jid] = val;
            }
            tmpParticipantsList[val.token_unique] = val;

            if (val.role !== "guest" && val.waiting_status === "accepted") {
              tmpParticipantsHost[val.token_unique] = val;
            }

            if (val.role === "guest" && val.waiting_status === "accepted") {
              tmpParticipantsGuest[val.token_unique] = val;
              if (tmpParticipantsWaiting[val.token_unique]) {
                delete tmpParticipantsWaiting[val.token_unique];
              }
            } else if (
              val.role === "guest" &&
              val.waiting_status === "pending"
            ) {
              tmpParticipantsWaiting[val.token_unique] = val;
            }

            if (val.audio && val.waiting_status === "accepted") {
              tmpParticipantsUnmuted[val.jid] = val;
            } else if (tmpParticipantsUnmuted[val.jid]) {
              delete tmpParticipantsUnmuted[val.jid];
            }

            ///Presentation view code
            if (val.waiting_status === "accepted" && val?.screenShare) {
              tmpparticipantsScreenShared[val.jid] = val.jid;
              screenSharedJid = val.jid;
            }

            ////////
          }
        }
      });
      ///////////////

      if (state.roomDetail?.enable_recent_speaker_feature) {
        if (prevInteractiveParticipantsIds.length > 0) {
          prevInteractiveParticipantsIds.forEach(id => {
            if (tmpParticipantsJID[id]) {
              interactiveParticipants[id] = tmpParticipantsJID[id];
            }
          });

          if (
            Object.keys(tmpParticipantsJID).length >
            prevInteractiveParticipantsIds.length ||
            Object.keys(tmpParticipantsJID).length ===
            prevInteractiveParticipantsIds.length
          ) {
            var uniqueArray = Object.keys(tmpParticipantsJID).filter(function (
              o1,
            ) {
              return !prevInteractiveParticipantsIds.some(function (o2) {
                return o1 === o2;
              });
            });
            uniqueArray.forEach(id => {
              if (tmpParticipantsJID[id]) {
                interactiveParticipants[id] = tmpParticipantsJID[id];
              }
            });
          }
        } else {
          interactiveParticipants = { ...tmpParticipantsJID };
        }
      }

      state.participantsJID = state.roomDetail?.enable_recent_speaker_feature ? interactiveParticipants : tmpParticipantsJID;
      // state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.screenSharedJid = screenSharedJid;
      if (!state.participantsScreenShared[screenSharedJid]) {
        if (screenSharedJid) {
          state.view = viewType.presentation;
        }
      }
      state.participantsScreenShared = tmpparticipantsScreenShared;

      console.log("confSlice state.participantsGuest", state.participantsGuest);
    },
    updParticipantsList: (state, action) => {
      // console.log('confSlice updParticipantsList', action.payload);
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };

      let val = action.payload;
      if (!val?.is_hidden) {
        if (val.token_unique === state.localUser.token_unique) {
          state.localUser = val;
        } else {
          let preScreenSharedValue = tmpParticipantsJID[val.jid]?.screenShare
            ? true
            : false;

          if (val.waiting_status === "accepted" && val.interaction_type === 'interactive') {
            tmpParticipantsJID[val.jid] = val;
          }

          tmpParticipantsList[val.token_unique] = val;
          if (val.role !== "guest" && val.waiting_status === "accepted") {
            tmpParticipantsHost[val.token_unique] = val;
          }

          if (val.role === "guest" && val.waiting_status === "accepted") {
            tmpParticipantsGuest[val.token_unique] = val;
            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (
            val.role === "guest" &&
            val.waiting_status === "declined"
          ) {
            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (val.role === "guest" && val.waiting_status === "pending") {
            tmpParticipantsWaiting[val.token_unique] = val;
          }

          if (val.audio && val.waiting_status === "accepted") {
            tmpParticipantsUnmuted[val.jid] = val;
          } else if (tmpParticipantsUnmuted[val.jid]) {
            delete tmpParticipantsUnmuted[val.jid];
          }

          ///Presentation view code

          let screenSharedJid = state.screenSharedJid;
          const prevScreenSharedJid = [];
          if (
            val.waiting_status === "accepted" &&
            val?.screenShare &&
            preScreenSharedValue !== true
          ) {
            tmpparticipantsScreenShared[val.jid] = val.jid;
            screenSharedJid = val.jid;
          } else if (
            val.waiting_status === "accepted" &&
            !val?.screenShare &&
            preScreenSharedValue === true
          ) {
            if (tmpparticipantsScreenShared[val.jid]) {
              delete tmpparticipantsScreenShared[val.jid];
            }

            if (screenSharedJid === val.jid) {
              Object.keys(tmpparticipantsScreenShared).forEach((key) =>
                prevScreenSharedJid.push(key)
              );
              if (prevScreenSharedJid.length > 0) {
                screenSharedJid = prevScreenSharedJid[0];
              } else {
                screenSharedJid = null;
              }
            }
          }
          // console.log(
          //   'updParticipantsList',
          //   screenSharedJid,
          //   val.jid,
          //   preScreenSharedValue,
          // );
          if (screenSharedJid === val.jid && preScreenSharedValue === false) {
            state.view = viewType.presentation;
          } else if (!screenSharedJid && preScreenSharedValue === true) {
            state.view = state.lastView;
          }
          state.screenSharedJid = screenSharedJid;
          state.participantsScreenShared = tmpparticipantsScreenShared;

          ///////////////
        }

        state.participantsJID = tmpParticipantsJID;
        state.participantsList = tmpParticipantsList;
        state.participantsUnmuted = tmpParticipantsUnmuted;
        state.participantsGuest = tmpParticipantsGuest;
        state.participantsHost = tmpParticipantsHost;
        state.participantsWaiting = tmpParticipantsWaiting;

        // console.log('confSlice state.participantsGuest', state.participantsGuest);
      }
    },
    remParticipantsList: (state, action) => {
      // console.log('confSlice remParticipantsList', action.payload);
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };

      let val = action.payload;
      delete tmpParticipantsJID[val.jid];

      delete tmpParticipantsList[val.token_unique];
      delete tmpParticipantsUnmuted[val.jid];
      delete tmpParticipantsGuest[val.token_unique];
      delete tmpParticipantsHost[val.token_unique];
      if (tmpParticipantsWaiting[val.token_unique]) {
        delete tmpParticipantsWaiting[val.token_unique];
      }
      ///Presentation view code
      let screenSharedJid = state.screenSharedJid;
      const prevScreenSharedJid = [];
      if (tmpparticipantsScreenShared[val.jid]) {
        delete tmpparticipantsScreenShared[val.jid];
      }

      if (screenSharedJid === val.jid) {
        Object.keys(tmpparticipantsScreenShared).forEach((key) =>
          prevScreenSharedJid.push(key)
        );
        if (prevScreenSharedJid.length > 0) {
          screenSharedJid = prevScreenSharedJid[0];
        } else {
          screenSharedJid = null;
        }
      }

      state.screenSharedJid = screenSharedJid;
      state.participantsScreenShared = tmpparticipantsScreenShared;

      if (!screenSharedJid) {
        state.view = state.lastView;
      }
      ///////////////
      state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
    },

    setMediaShareParticipants: (state, action) => {
      const jid = action.payload;
      console.log('MediaShare setMediaShareParticipants jid==>>', jid);
      state.mediaShareparticipantsJID = jid;
      // let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      // tmpparticipantsScreenShared[jid] = jid;
      // state.screenSharedJid = jid;
      // state.participantsScreenShared = tmpparticipantsScreenShared;

      // state.view = viewType.presentation;
    },
    remMediaShareParticipants: (state, action) => {
      const jid = action.payload;
      state.mediaShareparticipantsJID = null;
      console.log('MediaShare remMediaShareParticipants jid==>>', jid);
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let screenSharedJid = state.screenSharedJid;
      const prevScreenSharedJid = [];
      if (tmpparticipantsScreenShared[jid]) {
        delete tmpparticipantsScreenShared[jid];
      }
      console.log('MediaShare remMediaShareParticipants tmpparticipantsScreenShared==>>', tmpparticipantsScreenShared);
      if (screenSharedJid === jid) {
        Object.keys(tmpparticipantsScreenShared).forEach(key =>
          prevScreenSharedJid.push(key),
        );
        if (prevScreenSharedJid.length > 0) {
          screenSharedJid = prevScreenSharedJid[0];
        } else {
          screenSharedJid = null;
        }
      }

      state.screenSharedJid = screenSharedJid;
      state.participantsScreenShared = tmpparticipantsScreenShared;

      console.log('MediaShare remMediaShareParticipants screenSharedJid==>>', screenSharedJid);
      if (!screenSharedJid) {
        state.view = state.lastView;
      }
    },
    ////////////////////////////////////////////
    setRoomDetails: (state, action) => {
      state.roomDetail = action.payload;
    },
    setUserDetail: (state, action) => {
      state.localUser = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
      if (action.payload !== viewType.presentation) {
        state.lastView = state.view;
      }
    },
    galleryViewParticipants: (state, action) => {
      state.gallery_participants = action.payload;
    },

    setScreenDimension: (state, action) => {
      state.screenDimension = action.payload;
    },
    setWindowDimension: (state, action) => {
      state.windowDimension = action.payload;
    },

    trackAdd: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
        volume: 1,
      };
      state.tracks.push(trackdata);
    },
    trackRemove: (state, action) => {
      let track = action.payload;
      // let trackID = track.getId();
      let tracks = state.tracks.filter((t) => t.jitsiTrack !== track);
      state.tracks = tracks;
    },
    trackMediaTypeUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.map((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].mediaType = track.videoType;
        }
      });
    },
    trackMuteUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.map((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].muted = track.isMuted();
        }
      });
      console.log('state.mediaShareparticipantsJID', state.mediaShareparticipantsJID);
      if (
        track.getParticipantId() === state.mediaShareparticipantsJID &&
        track.type === 'video' &&
        track.isMuted()
      ) {
        state.mediaShareVideoTrack = false;
        let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
        const prevScreenSharedJid = [];
        Object.keys(tmpparticipantsScreenShared).forEach(key =>
          prevScreenSharedJid.push(key),
        );
        if (prevScreenSharedJid.length > 0) {
          state.screenSharedJid = prevScreenSharedJid[prevScreenSharedJid.length - 1];
        } else {
          state.screenSharedJid = null;
        }

        if (!state.screenSharedJid) {
          state.view = state.lastView;
        }
      } else if (
        track.getParticipantId() === state.mediaShareparticipantsJID &&
        track.type === 'video' &&
        !track.isMuted()
      ) {
        state.mediaShareVideoTrack = true;
        state.screenSharedJid = state.mediaShareparticipantsJID;
        state.view = viewType.presentation;
      }
    },

    setExtensionShow: (state, action) => {
      state.extensionShow = action.payload;
    },

    setExtensionDetails: (state, action) => {
      state.extensionDetails = action.payload;
    },
    setFeatureMsg: (state, action) => {
      state.featuredMsg = action.payload;
    },
    setMediaControls: (state, action) => {
      state.mediaControls = action.payload;
    },
    trackVolumeUpdate: (state, action) => {
      let participant = action.payload;
      let mediaIndex = state.tracks?.findIndex?.((track) => track.participantId === participant.participantId && track.type === 'audio');

      if (mediaIndex !== -1) {
        state.tracks[mediaIndex].volume = participant.volume;
      }
    },
    setParticipantsJIDList: (state, action) => {
      const list = action.payload;
      state.participantsJID = list;
    },
    setGalleryViewParticipants: (state, action) => {
      state.galleryViewParticipants = action.payload;
    },
    setTrackStreamStatus: (state, action) => {
      state.trackStreamStatus = { ...state.trackStreamStatus, ...action.payload };
    },
    setActiveParticipant: (state, action) => {
      state.activeParticipant = action.payload;
    },

  },
});

export const {
  setCurrentPage,
  setNumPages,
  setDominantSpeaker,
  setSocketConnected,
  setParticipantsList,
  updParticipantsList,
  remParticipantsList,
  setRoomDetails,
  setUserDetail,
  setView,
  galleryViewParticipants,
  setScreenDimension,
  setWindowDimension,
  trackAdd,
  trackRemove,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  setExtensionShow,
  setExtensionDetails,
  setFeatureMsg,
  remMediaShareParticipants,
  setMediaShareParticipants,
  setMediaControls,
  trackVolumeUpdate,
  setParticipantsJIDList,
  setGalleryViewParticipants,
  setTrackStreamStatus,
  setActiveParticipant,
} = confSlice.actions;

export default confSlice.reducer;
