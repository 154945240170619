/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExtensionWebpage from './extensionWebpage';
import ExtensionButton from './extensionButton';
import ExtensionCTA from './extensionCTA';
import ExtensionTimer from './extensionTimer';
import ExtensionMedia from './extensionMedia';
import { setView } from '../../reduxStore/confSlice';
import { viewType } from '../../utils/util';

const ExtensionHandler = () => {
  const {
    extensionDetails,
    screenDimension,
    view,
    lastView,
    mediaShareparticipantsJID
  } = useSelector(state => state.conf);
  const [extensionIframe, setExtensionIframe] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (extensionDetails?.extensionid === 52199) {
      const typeAudioVideo = /audio|video/i.test(extensionDetails?.type);
      if (typeAudioVideo) {
        // if (extensionDetails?.type?.includes('video')) {
        //   dispatch(setView(viewType.presentation));
        // } else {
        //   if (view === viewType.presentation && lastView) {
        //     dispatch(setView(lastView));
        //   }
        // }
        setExtensionIframe(false);
      } else {
        setExtensionIframe(true);
      }
    }
  }, [extensionDetails]);
  let { screenHeight, screenWidth } = screenDimension;
  const hideForAll = () => {

  };


  let extensionDisplay = '';

  if (extensionDetails?.extensionid === 36999) {
    extensionDisplay = !extensionDetails?.visible_to_guest ? 'none' : '';
  }

  const handlerStyle = {
    height: `${(screenHeight * (extensionDetails.height > 100 || extensionDetails.height < 0 ? 100 : extensionDetails.height)) / 100}px`,
    width: `${(screenWidth * (extensionDetails.width > 100 || extensionDetails.width < 0 ? 100 : extensionDetails.width)) / 100}px`,
    position: 'absolute',
    top: `${(screenHeight * (extensionDetails.top > 100 || extensionDetails.top < 0 ? 100 : extensionDetails.top)) / 100}px`,
    left: `${(screenWidth * (extensionDetails.left > 100 || extensionDetails.left < 0 ? 100 : extensionDetails.left)) / 100}px`,
    opacity: 1,
    zIndex: 1,
    display: extensionDisplay,
    background: extensionDetails?.extensionid === 68099 ? '#fff' : undefined,
  };

  return (
    extensionIframe ?
      <div style={handlerStyle}>
        {extensionDetails?.extensionid === 26999 ? (
          <ExtensionButton extensionDetails={extensionDetails} />
        ) : extensionDetails?.extensionid === 66199 ? (
          <ExtensionCTA extensionDetails={extensionDetails} />
        ) : extensionDetails?.extensionid === 36999 ? (
          <ExtensionTimer extensionDetails={extensionDetails} hideForAll={hideForAll} />
        ) : extensionDetails?.extensionid === 52199 ? (
          <ExtensionMedia extensionDetails={extensionDetails} />
        ) : (
          <ExtensionWebpage extensionDetails={extensionDetails} />
        )}
      </div> : <></>
  );
};

export default ExtensionHandler;
