import { setParticipantsJIDList, setTrackStreamStatus } from "../reduxStore/confSlice";
import { store } from "../reduxStore/store";
import { AVATAR_COLORS, AVATAR_OPACITY, viewType } from "./util";

export function getAvatarColor(initials) {
    let colorIndex = 0;

    if (initials) {
        let nameHash = 0;

        for (const s of initials) {
            nameHash += s.codePointAt(0);
        }

        colorIndex = nameHash % AVATAR_COLORS.length;
    }

    return `rgba(${AVATAR_COLORS[colorIndex]}, ${AVATAR_OPACITY})`;
}

export function _getAvatarStyle(color) {
    return {
        backgroundColor: color || undefined,
        height: '100 %',
        width: '100 %',
    };
}

export const checkLinkSubstitution = url => {
    const state = store.getState();
    const { roomDetail } = state.conf;
    let link_substitution = roomDetail?.link_substitution ?? [];

    let found = link_substitution?.find(v => url.includes(v.actual_text));

    if (found) {
        let isUrl = validURL(url);
        if (isUrl) {
            let isReplaceTextUrl = validURL(found.replace_text);

            if (isReplaceTextUrl) {
                url = found.replace_text;
                return url;
            } else {
                url = replaceLinks(url, found.actual_text, found.replace_text);
                return url;
            }
        } else {
            return url;
        }
    } else {
        return url;
    }
};

export function replaceLinks(inputText, replaceText, replaceWith) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, function (url) {
        return url.replace(replaceText, replaceWith);
    });

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, function (url) {
        return url.replace(replaceText, replaceWith);
    });

    //Url without http https
    replacePattern3 =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    replacedText = replacedText.replace(replacePattern3, function (url) {
        return url.replace(replaceText, replaceWith);
    });

    return replacedText;
}
function validURL(str) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
}
export const ShowActiveSpeakerOnFirstPage = () => {
    const state = store.getState();
    const { dispatch } = store;
    const { dominantSpeaker, participantsJID, localUser, maxVideos } = state.conf;
    if (dominantSpeaker !== null && dominantSpeaker !== localUser?.jid) {
        const galleryViewJid = Object.keys(participantsJID).slice(0, maxVideos - 1);
        const isDominatExist = galleryViewJid.includes(dominantSpeaker);
        if (!isDominatExist) {
            const UpdatedParticipantsJID = {};
            const tmpParticipantsJID = [...Object.keys(participantsJID)];
            const DominatIndex = tmpParticipantsJID.findIndex(
                item => item === dominantSpeaker,
            );
            const sortedList = getParticipiantsJidSortedData(
                Object.values(participantsJID).slice(0, maxVideos - 1),
            );
            const leastUserSpeakIndex = tmpParticipantsJID.findIndex(
                item => item === sortedList[sortedList.length - 1].jid,
            );
            if (DominatIndex !== -1) {
                const tmpData = tmpParticipantsJID[leastUserSpeakIndex];
                tmpParticipantsJID[leastUserSpeakIndex] =
                    tmpParticipantsJID[DominatIndex];
                tmpParticipantsJID[DominatIndex] = tmpData;
                tmpParticipantsJID.forEach(jidItem => {
                    UpdatedParticipantsJID[jidItem] = participantsJID[jidItem];
                });
                dispatch(setParticipantsJIDList(UpdatedParticipantsJID));
            }
        }
    }
};

export const getParticipiantsJidSortedData = data => {
    let list = [...data];
    let speakUsers = list.filter(z => z.last_speak_time !== undefined);
    const unSpeakUser = list.filter(b => b.last_speak_time === undefined);
    if (speakUsers.length > 0) {
        const sortedSpeakUsers = speakUsers.sort(function (x, y) {
            return y.last_speak_time - x.last_speak_time;
        });
        list = [...sortedSpeakUsers, ...unSpeakUser];
    }
    return list;
};

export const getPartcipiantsUserIds = () => {
    const state = store.getState();
    const { view, galleryViewParticipants, tracks } = state.conf;
    const usersId = [];
    if (view !== 'gallery') {
        galleryViewParticipants.forEach(item => {
            let trackArray = tracks.filter(
                t =>
                    t.participantId === item.jid &&
                    t.type === 'video' &&
                    t.mediaType === 'camera' &&
                    t.muted === false
            );
            if (trackArray.length > 0) {
                let track = trackArray[0];
                usersId.push(track.jitsiTrack.getSourceName());
            }
        });
    }

    return usersId;
};

export const handleTrackStreamingStatusChanged = (
    jitsiTrack,
    streamingStatus,
) => {
    const { dispatch } = store;
    const state = store.getState();
    const {
        galleryViewParticipants,
        activeParticipant,
        roomDetail
    } = state.conf;
    let trackStreamStatus = jitsiTrack?.trackStreamStatus;

    let endpointId = jitsiTrack?.ownerEndpointId;
    let videoTypeCamera = jitsiTrack?.videoType === 'camera';

    let galleryParticipantsJids = galleryViewParticipants.map(v => v?.jid);
    console.log('galleryParticipantsJids', galleryParticipantsJids, roomDetail?.recorder_view, activeParticipant?.jid, endpointId);
    if (roomDetail?.recorder_view === viewType.gallery) {
        if (galleryParticipantsJids?.includes(endpointId) && videoTypeCamera) {
            let streamStatus = {
                [jitsiTrack.ownerEndpointId]: streamingStatus,
            };
            dispatch(setTrackStreamStatus(streamStatus));
        }
    } else if (
        roomDetail?.recorder_view === viewType.active
    ) {

        if (activeParticipant?.jid === endpointId) {
            let streamStatus = {
                [jitsiTrack.ownerEndpointId]: streamingStatus,
            };
            dispatch(setTrackStreamStatus(streamStatus));
        }
    }
};
export const isTrackStreamingStatusActive = videoTrack => {
    const state = store.getState();
    const { trackStreamStatus } = state.conf;
    let currentStatus = trackStreamStatus[videoTrack?.ownerEndpointId];
    console.log('videoTrack', videoTrack)
    let isActive =
        currentStatus === 'inactive' ||
            currentStatus === 'restoring' ||
            currentStatus === 'interrupted'
            ? false
            : true;

    return isActive;
};

export const getTrackVideoStatus = videoTrack => {
    const state = store.getState();
    const { trackStreamStatus } = state.conf;
    let currentStatus = trackStreamStatus[videoTrack?.ownerEndpointId];

    return currentStatus;
};